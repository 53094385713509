<template>
  <div>
    <Button
      icon="pi pi-calendar"
      class="p-button-text"
      @click="toggleCalendar"
    />
    <CalendarPanel v-if="showCalendar" />
  </div>
</template>

<script setup>
import { ref, nextTick, watch } from "vue";
import CalendarPanel from "./CalendarPanel.vue";
import Button from "primevue/button";
const showCalendar = ref(false);

const toggleCalendar = (e) => {
  if (e) {
    e.calHandled = true;
  }
  showCalendar.value = !showCalendar.value;
};

// Handle clicks outside the popover to close it
const handleClickOutside = (event) => {
  if (!event || event.calHandled) {
    return;
  }
  if (
    !document.querySelector(".calendar-panel")?.contains(event.target) &&
    !document.querySelector(".p-datepicker-panel")?.contains(event.target)
  ) {
    toggleCalendar();
  }
};

watch(showCalendar, () => {
  if (showCalendar.value) {
    nextTick(() => {
      document.addEventListener("click", handleClickOutside);
    });
  } else {
    document.removeEventListener("click", handleClickOutside);
  }
});
</script>
