import { reactive, readonly, watch } from "vue";

import { Capacitor } from "@capacitor/core";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

async function initializeSessionState() {
  const { useAuthStore } = await import("../hooks/useAuthStore");

  const { validateTokenRefresh } = useAuthStore();
  const setCheckAuthenticated = async (state) => {
    if (state?.refreshToken) {
      await validateTokenRefresh(state.token, state.refreshToken, state.user);
    }
  };
  try {
    if (
      Capacitor.getPlatform() === "ios" ||
      Capacitor.getPlatform() === "android"
    ) {
      const storedToken = await SecureStoragePlugin.get({ key: "authToken" });
      await setCheckAuthenticated(JSON.parse(storedToken?.value || "{}"));
    } else {
      await setCheckAuthenticated(
        JSON.parse(window?.sessionStorage.getItem("state")) || {}
      );
    }
  } catch (e) {
    console.error("Error retrieving stored token:", e);
  }
  state.isAuthenticating = false;
}

const state = reactive({
  user: null,
  isAuthenticated: false,
  isAuthenticating: true,
  token: null,
  authCode: null,
  refreshToken: null,
  deviceId: null,
  labels: [],
  users: [],
  notes: [],
  roles: [],
  events: [],
  tasks: [],
  fileMetadata: [],
  comments: [],
  reminders: [],
  collaborators: [],
  locations: [],
  checkinAlerts: [],
  contacts: [],
  prefrences: null,
  filters: {
    search: "",
    sort: "created_at_desc",
    toggles: {
      hasFile: null,
      hasReminder: null,
      hasTask: null,
      pinned: null,
    },
    labels: [],
    owner: null,
    dateRange: null,
    colors: [],
  },
  invites: [],
  userSuggestions: [],
  userContactSuggestions: [],
  isSidebarOpen: window.innerWidth > 767,
  // sessionState will be merged after initialization
});

async function initializeStore() {
  const sessionState = await initializeSessionState();
  Object.assign(state, sessionState);
}

initializeStore(); // Initialize the session state when the app loads

watch(
  () => ({
    refreshToken: state.refreshToken,
    user: state.user,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
    deviceId: state.deviceId,
  }),
  () => {
    const authData = JSON.stringify({
      user: state.user,
      token: state.token,
      refreshToken: state.refreshToken,
      isAuthenticated: state.isAuthenticated,
      deviceId: state.deviceId,
    });

    if (
      Capacitor.getPlatform() === "ios" ||
      Capacitor.getPlatform() === "android"
    ) {
      SecureStoragePlugin.set({ key: "authToken", value: authData });
    } else {
      window?.sessionStorage.setItem("state", authData);
    }
  }
);

const methods = {
  setUser(user) {
    state.user = user;
    state.isAuthenticated = !!user;
  },
  setToken(token) {
    state.token = token;
  },
  setRefreshToken(token) {
    state.refreshToken = token;
  },
  clearAuth() {
    state.user = null;
    state.token = null;
    state.refreshToken = null;
    state.isAuthenticated = false;
  },
  addModel(type, data) {
    state[type].unshift(data);
  },
  updateModel(type, id, updatedData) {
    const collection = state[type];
    if (collection && typeof collection[id] !== "undefined") {
      collection[id] = updatedData;
    } else {
      const index = collection?.findIndex((item) => item.id === id);
      if (index !== -1 && state[type]) {
        state[type][index] = updatedData;
      } else {
        console.log(`could not update ${type} ${index} ${collection} ${id}`);
      }
    }
  },
  removeModel(type, id) {
    state[type] = state[type].filter((item) => item.id !== id);
  },
  setState(type, value) {
    state[type] = value;
  },
};

export default {
  state: readonly(state),
  mutations: methods,
};
