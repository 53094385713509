<template>
  <div ref="modalImageContainer" class="img-wrap">
    <!-- Display a loading spinner or placeholder while the image is loading -->
    <div v-if="loading" class="image-placeholder">Loading...</div>

    <!-- Display the image if no error and image is valid -->
    <img v-if="inView && !error" class="modal-media" />

    <!-- Display a fallback UI if the image fails to load -->
    <div v-if="error" class="image-placeholder">
      <span>Error loading image</span>
      <button @click="retryLoad">Retry</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, toRefs, watch } from "vue";

const modalImageContainer = ref(null);
const loading = ref(true);
const error = ref(false);
const inView = ref(false);
const imageCache = ref(new Map());

const props = defineProps({
  signedUrl: String,
});

const { signedUrl } = toRefs(props);

// Handle image decoding and loading success
const loadImage = () => {
  const img = new Image();
  img.src = signedUrl.value;

  img
    .decode()
    .then(() => {
      const imageElement = modalImageContainer.value.querySelector("img");
      if (imageElement) {
        imageElement.src = signedUrl.value;
      }
      loading.value = false;
      error.value = false;

      // Cache the image if not already cached
      if (!imageCache.value.has(signedUrl.value)) {
        imageCache.value.set(signedUrl.value, signedUrl.value);
      }
    })
    .catch((err) => {
      console.error("Error loading image:", err);
      loading.value = false;
      error.value = true;
    });
};

// Retry loading the image
const retryLoad = () => {
  loading.value = true;
  error.value = false;
  loadImage();
};

watch(signedUrl, () => {
  if (signedUrl.value && inView.value) {
    loadImage();
  }
});
// Lazy loading with Intersection Observer
onMounted(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        inView.value = true;
        if (modalImageContainer.value) {
          observer.unobserve(modalImageContainer.value); // Stop observing once it is in view
          loadImage(); // Start loading the image when in view
        }
      }
    });
  });

  if (modalImageContainer.value) {
    observer.observe(modalImageContainer.value); // Observe the image container
  }
});
</script>

<style>
.image-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
}
.img-wrap img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
</style>
