import { ref, computed } from "vue";
import axios from "axios";
import api from "../utils/api";
import heic2any from "heic2any";
import Pica from "pica"; // Import Pica for resizing

const uploadProgress = ref({});
const uploadStatus = ref({});
const uploadingFiles = ref({});
const abortControllers = ref({});
const chunkSize = 5 * 1024 * 1024; // 5MB
const pica = new Pica(); // Initialize Pica

// Compression settings
const MAX_WIDTH = 2000; // Maximum width to resize images
const QUALITY = 0.8; // JPEG quality (0.0 - 1.0)

export function useUploadStore() {
  // Convert HEIC to JPEG and compress the image if necessary
  const convertToJpg = async (file) => {
    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      // If it's not an image, return the original file
      return file;
    }
    let imageFile = file;

    // Convert HEIC/HEIF files to JPEG
    if (file.type === "image/heic" || file.type === "image/heif") {
      const blob = await heic2any({ blob: file, toType: "image/jpeg" });
      imageFile = new File([blob], file.name.replace(/\.[^/.]+$/, ".jpg"), {
        type: "image/jpeg",
      });
    }

    // Compress the image
    const compressedFile = await compressImage(imageFile);
    return compressedFile;
  };

  // Compress image using canvas and Pica
  const compressImage = async (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (event) => {
        img.src = event.target.result;
      };

      img.onload = async () => {
        // Create an off-screen canvas
        const canvas = document.createElement("canvas");
        // Set the canvas size based on the image's aspect ratio
        let width = img.width;
        let height = img.height;
        if (width > MAX_WIDTH) {
          height = (MAX_WIDTH / width) * height;
          width = MAX_WIDTH;
        }

        canvas.width = width;
        canvas.height = height;

        // Use Pica to resize the image with better quality
        const resultCanvas = await pica.resize(img, canvas);

        // Convert the canvas to a Blob
        resultCanvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error("Image compression failed."));
              return;
            }

            // Create a new file from the compressed Blob
            const compressedFile = new File(
              [blob],
              file.name.replace(/\.[^/.]+$/, ".jpg"),
              {
                type: "image/jpeg",
                lastModified: Date.now(),
              }
            );

            resolve(compressedFile);
          },
          "image/jpeg",
          QUALITY
        );
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const initiateUpload = async (file) => {
    const response = await api.post("/api/files/initiate-upload", {
      fileName: file.name,
      fileType: file.type,
    });
    return response.data;
  };

  const uploadChunk = async (
    file,
    partNumber,
    uploadId,
    start,
    end,
    noteId,
    fileId,
    fileName
  ) => {
    const chunk = file.slice(start, end);
    const response = await api.get("/api/files/signed-url", {
      params: {
        fileName: fileName,
        fileType: file.type,
        partNumber,
        uploadId,
      },
    });

    const signedUrl = response.data.signedUrl;
    const controller = new AbortController();
    abortControllers.value[`${noteId}-${fileId}`] = controller;

    const uploadResponse = await axios.put(signedUrl, chunk, {
      headers: {
        "Content-Type": file.type,
      },
      signal: controller.signal,
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (!uploadProgress.value[noteId]) {
          uploadProgress.value[noteId] = {};
        }
        uploadProgress.value[noteId][fileId] = progress;
      },
    });

    const etag = uploadResponse.headers.etag;
    return { ETag: etag, PartNumber: partNumber, Size: chunk.size };
  };

  const completeUpload = async (fileName, uploadId, parts, fileId) => {
    return await api.post("/api/files/complete-upload", {
      fileName,
      uploadId,
      parts,
      fileId,
    });
  };

  const uploadFile = async (file, noteId = "new") => {
    uploadStatus.value[`${noteId}-${fileId}`] = "uploading";
    const convertedFile = await convertToJpg(file);
    const { uploadId, fileId, fileName } = await initiateUpload(
      convertedFile,
      noteId
    );

    if (!uploadingFiles.value[noteId]) {
      uploadingFiles.value[noteId] = [];
    }

    uploadingFiles.value[noteId].push({
      id: fileId,
      name: fileName,
      filePath: fileName,
    });

    const parts = [];
    for (let i = 0; i < convertedFile.size; i += chunkSize) {
      const partNumber = Math.floor(i / chunkSize) + 1;
      const part = await uploadChunk(
        convertedFile,
        partNumber,
        uploadId,
        i,
        i + chunkSize,
        noteId,
        fileId,
        fileName
      );
      parts.push(part);
    }

    const newFile = await completeUpload(fileName, uploadId, parts, fileId);

    delete uploadStatus.value[`${noteId}-${fileId}`];
    uploadingFiles.value[noteId] = uploadingFiles.value[noteId].filter(
      (n) => n.id !== fileId
    );

    return newFile.data;
  };

  const cancelUpload = (noteId = "new", fileId) => {
    const key = `${noteId}-${fileId}`;
    if (abortControllers.value[key]) {
      abortControllers.value[key].abort();
      delete abortControllers.value[key];

      uploadingFiles.value[noteId] = uploadingFiles.value[noteId].filter(
        (file) => file.id !== fileId
      );
    }
  };

  const getUploadingFilesByNoteId = (noteId = "new") =>
    computed(() => {
      return uploadingFiles.value[noteId] || [];
    });

  return {
    uploadFile,
    uploadProgress,
    uploadStatus,
    cancelUpload,
    getUploadingFilesByNoteId,
  };
}
