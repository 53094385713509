import { computed, ref } from "vue";
import store from "../store";
import api from "../utils/api";

export function useTaskStore() {
  const isLoading = ref(false);
  const tasks = computed(() => store.state.tasks);

  async function fetchTasks(query) {
    try {
      isLoading.value = true;
      const response = await api.get("/api/tasks", { params: query });
      store.mutations.setState("tasks", response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      isLoading.value = false;
    }
  }

  async function createTask(taskData) {
    try {
      const response = await api.post("/api/tasks", taskData);
      const note = store.state.notes?.find(
        (note) => note.id === response.data.noteId
      );
      if (note) {
        store.mutations.updateModel("notes", response.data.noteId, {
          ...note,
          tasks: [...note.tasks, response.data],
        });
      }
      return response.data;
    } catch (error) {
      console.error("Error creating task:", error);
      throw error;
    }
  }

  async function updateTask(id, taskData) {
    try {
      const response = await api.put(`/api/tasks/${id}`, taskData);
      const note = store.state.notes?.find(
        (note) => note.id === response.data.noteId
      );

      const index = note?.tasks?.map((t) => t.id)?.indexOf(response.data.id);
      if (index >= 0) {
        const updatedTasks = [...note.tasks];
        updatedTasks[index] = response.data;
        store.mutations.updateModel("notes", response.data.noteId, {
          ...note,
          tasks: updatedTasks,
        });
      }
      return response.data;
    } catch (error) {
      console.error("Error updating task:", error);
      throw error;
    }
  }

  async function deleteTask(id, noteId) {
    try {
      await api.delete(`/api/tasks/${id}`);
      const note = store.state.notes?.find((note) => note.id === noteId);
      if (note) {
        store.mutations.updateModel("notes", noteId, {
          ...note,
          tasks: note.tasks.filter((t) => t.id !== id),
        });
      }
    } catch (error) {
      console.error("Error deleting task:", error);
      throw error;
    }
  }

  async function fetchTaskById(taskId) {
    try {
      const response = await api.get(`/api/tasks/${taskId}`);
      store.mutations.addModel("tasks", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching task by id:", error);
      throw error;
    }
  }

  async function searchTasks(query) {
    try {
      const response = await api.get("/api/tasks/search", { params: query });
      return response.data;
    } catch (error) {
      console.error("Error searching tasks:", error);
      throw error;
    }
  }

  return {
    tasks,
    isLoading,
    fetchTasks,
    createTask,
    updateTask,
    deleteTask,
    fetchTaskById,
    searchTasks,
  };
}
