<template>
  <div class="calendar-panel" ref="calendarPanel">
    <DatePicker
      v-model="selectedDate"
      :inline="true"
      :showIcon="false"
      :showButtonBar="true"
      dateFormat="dd/mm/yy"
      :monthNavigator="true"
      :yearNavigator="true"
      :viewDate="selectedDate"
      :showOtherMonths="true"
      :appendTo="true"
      :selectOtherMonths="true"
      @select="selectDate"
      @month-change="handleViewDateChange"
      @year-change="handleViewDateChange"
    >
      <template #date="{ date, otherMonth }">
        <div
          :class="[
            'custom-day',
            {
              'event-day': isEventDay(date.day, date.month, date.year),
              'other-month-day': otherMonth,
            },
          ]"
        >
          <span class="day-name">{{ date.day }}</span>
          <span
            v-if="isEventDay(date.day, date.month, date.year)"
            class="dot"
          ></span>
        </div>
      </template>
    </DatePicker>
    <h3 class="selected">Events on {{ selectedDate.toLocaleDateString() }}</h3>
    <div v-if="dayEvents.length > 0" class="event-list">
      <ul>
        <li v-for="event in dayEvents" :key="event.id">
          <button @click="openNote(event.id)" class="pb-1">
            <div class="text-sm block text-left mb-1">
              <Chip
                class="label"
                :label="event.task_id ? 'Task Due' : 'Reminder'"
              />
              {{
                format(
                  event.task_id ? event.due_date : event.instance_start,
                  "E MMM dd, yyy hh:mm a"
                )
              }}
            </div>
            {{ event.task_id ? event.description : event.content }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useEventStore } from "../hooks/useEventStore";
import Chip from "primevue/chip";
import DatePicker from "primevue/datepicker";
import { format } from "date-fns";

const selectedDate = ref(new Date());
const { fetchEvents, calculateDateRange, events } = useEventStore();
const calendarPanel = ref(null);

// Calculate the initial date range
const { startDate, endDate } = calculateDateRange(new Date());

const router = useRouter();
const route = useRoute();

// Load events for the initial range
onMounted(() => {
  fetchEvents({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  // Add a click listener to stop propagation on all button clicks within the panel
  if (calendarPanel.value) {
    calendarPanel.value.addEventListener("click", (event) => {
      if (
        event.target.tagName === "BUTTON" ||
        event.target.closest("button") ||
        event.target.closest(".p-datepicker-day") ||
        event.target.closest(".p-datepicker-year") ||
        event.target.closest(".p-datepicker-month")
      ) {
        event.stopPropagation();
      }
    });
  }
});

onBeforeUnmount(() => {
  // Clean up the event listener when the component is unmounted
  if (calendarPanel.value) {
    calendarPanel.value.removeEventListener("click", (event) => {
      if (
        event.target.tagName === "BUTTON" ||
        event.target.closest("button") ||
        event.target.closest(".p-datepicker-day") ||
        event.target.closest(".p-datepicker-year") ||
        event.target.closest(".p-datepicker-month")
      ) {
        event.stopPropagation();
      }
    });
  }
});

const dayEvents = computed(() => {
  return events.value.filter(
    (event) =>
      new Date(
        event.task_id ? event.due_date : event.instance_start
      ).toDateString() === selectedDate.value.toDateString()
  );
});

const highlightDates = computed(() =>
  events.value.map((event) => new Date(event.instance_start))
);

const isEventDay = (day, month, year) => {
  const dateToCheck = new Date(year, month, day);
  return highlightDates.value.some(
    (highlightDate) =>
      highlightDate.toDateString() === dateToCheck.toDateString()
  );
};

const selectDate = (date, e) => {
  if (e) {
    e.stopPropagation();
    e.calHandled = true;
  }
  selectedDate.value = date;
  updateEventsForDateRange(date);
};

const handleViewDateChange = (dateInfo) => {
  // Create a Date object for the 1st of the specified month and year
  const firstOfMonth = new Date(
    dateInfo.year,
    dateInfo.month - 1,
    selectedDate.value.getDate()
  );

  console.log(firstOfMonth);
  updateEventsForDateRange(firstOfMonth);
  selectedDate.value = firstOfMonth;
};

const updateEventsForDateRange = (date) => {
  const { startDate, endDate } = calculateDateRange(date);
  fetchEvents({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });
};

const openNote = (id) => {
  router.push(`/note/${id}`, { query: route.query });
};
</script>

<style>
.calendar-panel .custom-day {
  position: relative;
  padding: 5px;
  text-align: center;
}
.calendar-panel .selected {
  color: var(--corp-text-color);
}
.calendar-panel .event-day {
  font-weight: bold;
  color: var(--primary-color, #42a5f5);
}

.calendar-panel .other-month-day {
  color: #c0c0c0;
}

.calendar-panel .dot {
  width: 3px;
  height: 3px;
  background-color: var(--primary-color, #42a5f5);
  border-radius: 50%;
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
}

.calendar-panel .p-datepicker .p-highlight {
  position: relative;
}
.calendar-panel .p-datepicker .day-name {
  color: var(--corp-text-color);
}
.calendar-panel .p-datepicker .p-highlight::after {
  content: "";
  width: 3px;
  height: 3px;
  background-color: var(--primary-color, #42a5f5);
  border-radius: 50%;
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
}

.calendar-panel .event-list {
  margin-top: 15px;
}

.calendar-panel {
  position: fixed;
  height: calc(100vh - 50px);
  top: 50px; /* Adjust based on your header height */
  right: 0;
  width: 300px;
  background: var(--sidebar-back);
  border: 1px solid #ccc;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.calendar-panel .event-list {
  margin-top: 15px;
}
.calendar-panel .event-list ul {
  padding: 0;
  overflow: auto;
}
.calendar-panel .event-list li {
  list-style: none;
}
.calendar-panel .event-list li button {
  text-align: left;
  border: 0;
  border-bottom: 2px solid var(--corp-text-color);
  color: var(--corp-text-color);
  display: block;
  width: 100%;
  background: none;
  margin-bottom: 1rem;
}
.calendar-panel .p-datepicker-clear-button {
  display: none;
}
.calendar-panel .label {
  font-size: 0.6rem;
}
</style>
