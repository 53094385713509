// locationHelper.js
import { Capacitor } from "@capacitor/core";
import { useNotify } from "../hooks/useNotify";
import store from "../store";
import api from "../utils/api";

let lastKnownPosition = null;

export const isNative =
  Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android";

export async function handlePositionUpdate(position, err, opts = {}) {
  if (err) {
    console.error("Error getting location:", err);
    return;
  }

  const { latitude, longitude } = position.coords;
  if (lastKnownPosition) {
    const distanceMoved = calculateDistance(
      lastKnownPosition.latitude,
      lastKnownPosition.longitude,
      latitude,
      longitude
    );
    lastKnownPosition = { latitude, longitude };

    if (distanceMoved < 100) {
      console.log("No significant movement detected");
      return;
    }
  }
  postLocation(latitude, longitude, generateDeviceId(), opts);
}

export const manuallySetLastKnownPosition = (position) => {
  lastKnownPosition = { ...position };
};
export async function postLocation(latitude, longitude, deviceId, opts) {
  const { notify } = useNotify();
  const data = {
    deviceId,
    latitude,
    longitude,
  };

  try {
    const response = await api.post("/api/checkin", data);
    const { checkinAlerts } = store.state;
    if (!opts.skipNotify) {
      for (const not of response.data) {
        if (!checkinAlerts?.find((n) => n.id === not.id)) {
          let title = `${
            not.locationTriggerType === "arrive" ? "Arrive:" : "Leave:"
          } ${not.location.address}`;
          title = title.length > 100 ? title.slice(0, 100) : title;

          let message = not.note.content;
          message = message.length > 100 ? message.slice(0, 100) : message;

          await notify(title, message, { noteId: not.noteId });
        }
      }
    }

    store.mutations.setState("checkinAlerts", response.data);
  } catch (error) {
    console.error("Error posting location:", error);
  }
}

function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371e3;
  const φ1 = lat1 * (Math.PI / 180);
  const φ2 = lat2 * (Math.PI / 180);
  const Δφ = (lat2 - lat1) * (Math.PI / 180);
  const Δλ = (lon2 - lon1) * (Math.PI / 180);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

function generateDeviceId() {
  const key = "device-id";
  let deviceId = localStorage.getItem(key);

  if (!deviceId) {
    const userAgent = navigator.userAgent;
    const screenSize = `${screen.width}x${screen.height}`;
    const randomComponent = Math.random().toString(36).substring(2, 15);
    deviceId = btoa(`${userAgent}-${screenSize}-${randomComponent}`);
    localStorage.setItem(key, deviceId);
  }
  store.mutations.setState("deviceId", deviceId);
  return deviceId;
}
