<template>
  <NotesPage v-if="isAuthenticated" />

  <!-- <LandingPage v-else /> -->
</template>

<script setup>
import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";

// import LandingPage from "./LandingPage.vue";
import NotesPage from "./NotesPage.vue";
import { useAuthStore } from "../hooks/useAuthStore";
const { isAuthenticated } = useAuthStore();

const router = useRouter();
onBeforeMount(() => {
  if (!isAuthenticated.value) {
    // Perform your redirect logic here
    router.push("/login");
  }
});
</script>
