<template>
  <div
    class="flex justify-content-center align-items-center min-h-screen verify"
  >
    <div class="surface-card p-4 shadow-2 border-round text-left">
      <h2 class="text-center">Email Verification</h2>
      <p v-if="loading">Verifying your email...</p>
      <p v-else-if="verificationSuccess">
        Your email has been verified successfully! You can now
        <router-link to="/login">login</router-link>.
      </p>
      <p v-else-if="verificationError">
        {{ verificationError }}. Please try again or contact support.
      </p>
      <Button
        v-if="!loading && !verificationSuccess"
        label="Retry"
        @click="verifyEmail"
        class="mt-3 w-full"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "../hooks/useAuthStore.js";

const route = useRoute();
const router = useRouter();
const token = route.query.token || null;
const loading = ref(true);
const verificationSuccess = ref(false);
const verificationError = ref(null);

const { verifyEmailToken } = useAuthStore();

const verifyEmail = async () => {
  loading.value = true;
  verificationError.value = null;
  if (token) {
    try {
      await verifyEmailToken(token);
      verificationSuccess.value = true;
      setTimeout(() => {
        router.push("/login");
      }, 5000); // Redirect after 5 seconds
    } catch (error) {
      verificationError.value = error.message || "Verification failed";
    }
  } else {
    verificationError.value = "Invalid or missing token";
  }
  loading.value = false;
};

onMounted(() => {
  verifyEmail();
});
</script>

<style scoped>
.verify {
  margin: 0 auto;
  width: 290px;
}
.min-h-screen {
  min-height: calc(var(--vh, 1vh) * 100);
}
</style>
