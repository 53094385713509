import { ref } from "vue";

export function useSwipeHandler({
  onSwipeLeft,
  onSwipeRight,
  onSwipeUp,
  onSwipeDown,
}) {
  const touchStartX = ref(0);
  const touchEndX = ref(0);
  const touchStartY = ref(0);
  const touchEndY = ref(0);

  const handleTouchStart = (event) => {
    touchStartX.value = event.changedTouches[0].screenX;
    touchStartY.value = event.changedTouches[0].screenY;
  };

  const handleTouchEnd = (event) => {
    touchEndX.value = event.changedTouches[0].screenX;
    touchEndY.value = event.changedTouches[0].screenY;

    handleSwipe();
  };

  const handleSwipe = () => {
    const swipeThreshold = 150; // Minimum X distance to recognize a swipe
    const swipeYThreshold = 100; // Minimum Y distance to recognize a swipe
    const swipeXDistance = touchEndX.value - touchStartX.value;
    const swipeYDistance = touchEndY.value - touchStartY.value;

    if (swipeXDistance > swipeThreshold) {
      // Swipe right
      onSwipeRight && onSwipeRight();
    } else if (swipeXDistance < -swipeThreshold) {
      // Swipe left
      onSwipeLeft && onSwipeLeft();
    }

    if (swipeYDistance > swipeYThreshold) {
      // Swipe up
      onSwipeUp && onSwipeUp();
    } else if (swipeYDistance < -swipeYThreshold) {
      // Swipe down
      onSwipeDown && onSwipeDown();
    }
  };

  return {
    handleTouchStart,
    handleTouchEnd,
  };
}
