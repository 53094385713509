<template>
  <div class="sub-panel">
    <p class="sub-panel-title">Repeat</p>
    <div class="gap-2 flex">
      <div class="text-left">Every</div>
      <div class="flex gap-1 ml-4">
        <InputNumber
          v-model="localInterval"
          :min="1"
          class="flex-1 max-w-3rem"
          inputClass="w-full p-1"
        />
        <Select
          v-model="localFrequency"
          :options="repeatUnitOptions"
          optionLabel="label"
          optionValue="value"
        />
      </div>
    </div>
    <Divider />
    <div class="gap-2 flex">
      <div class="text-left">Ends</div>
      <div class="flex flex-column gap-2 w-full overflow-hidden">
        <div class="flex flex-1 align-items-center gap-2">
          <RadioButton
            v-model="localEndCondition"
            :value="'never'"
            label="Never"
            inputId="never"
          />
          <label for="never">Never</label>
        </div>
        <div class="flex flex-1 align-items-center gap-2">
          <RadioButton
            v-model="localEndCondition"
            :value="'after'"
            label="After"
            inputId="after"
          />
          <label for="after" class="flex flex-1 gap-2 align-items-center">
            After
            <InputNumber
              v-model="localEndAfterOccurrences"
              :min="1"
              class="flex-1 max-w-3rem"
              inputClass="w-full px-1"
              @focus="localEndCondition = 'after'"
            />
            <span>events</span>
          </label>
        </div>
        <div class="flex align-items-center gap-2">
          <RadioButton v-model="localEndCondition" :value="'on'" label="On" />
          On
          <DatePicker
            v-model="localEndOnDate"
            format="dd/mm/yy"
            @focus="localEndCondition = 'on'"
            class="end-on-date"
          />
        </div>
      </div>
    </div>
    <div class="gap-2 w-full">
      <!-- <p class="text-left w-full">Repeats {{ repeatSummary }}</p> -->
      <div class="w-full flex gap-1">
        <Button
          label="Back"
          text
          icon="pi pi-arrow-left"
          class="flex-1 justify-content-start"
          @click="showPanel('date')"
        />
        <Button label="Done" class="flex-1" icon="pi pi-check" @click="save" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from "vue";
// import { generateRRule, getRRuleText } from "../../utils/helpers";
import InputNumber from "primevue/inputnumber";
import Select from "primevue/select";
import RadioButton from "primevue/radiobutton";
import DatePicker from "primevue/datepicker";
import Button from "primevue/button";
import Divider from "primevue/divider";

const props = defineProps({
  frequency: Number,
  interval: Number,
  endCondition: String,
  endAfterOccurrences: { type: Number, default: 1 },
  endOnDate: Date,
  repeatUnitOptions: Array,
});

const emit = defineEmits([
  "update:frequency",
  "update:interval",
  "update:endCondition",
  "update:endAfterOccurrences",
  "update:endOnDate",
  "save",
  "showPanel",
]);

const localFrequency = ref(props.frequency || 1);
const localInterval = ref(props.interval || 1);
const localEndCondition = ref(props.endCondition);
const localEndAfterOccurrences = ref(props.endAfterOccurrences || 1);
const localEndOnDate = ref(props.endOnDate);

// Watchers to update props
watch(localFrequency, (newFrequency) => emit("update:frequency", newFrequency));
watch(localInterval, (newInterval) => emit("update:interval", newInterval));
watch(localEndCondition, (newEndCondition) =>
  emit("update:endCondition", newEndCondition)
);
watch(localEndAfterOccurrences, (newEndAfterOccurrences) =>
  emit("update:endAfterOccurrences", newEndAfterOccurrences)
);
watch(localEndOnDate, (newEndOnDate) => emit("update:endOnDate", newEndOnDate));

// Computed property to generate the RRule and its description
// const repeatSummary = computed(() => {
//   const rule = generateRRule({
//     frequency: localFrequency.value,
//     interval: localInterval.value,
//     endCondition: localEndCondition.value,
//     endAfterOccurrences: localEndAfterOccurrences.value,
//     endOnDate: localEndOnDate.value,
//   });
//   return getRRuleText(rule);
// });

const save = () => {
  emit("save");
};

const showPanel = (panel) => {
  emit("showPanel", panel);
};
</script>

<style scoped>
.end-on-date {
  margin-left: 13px;
}
</style>
