<template>
  <div class="notifications-icon">
    <Button
      icon="pi pi-bell"
      class="p-button-text"
      @click="toggleNotificationsPanel"
    />
    <span v-if="hasNotifications" class="badge">{{ totalNotifications }}</span>
    <NotificationsPanel v-if="showNotificationsPanel" />
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from "vue";
import Button from "primevue/button";
import NotificationsPanel from "./NotificationsPanel.vue";
import { useDeviceLocation } from "../hooks/useDeviceLocation";
import { useInvitationStore } from "../hooks/useInvitationStore";

const { invites } = useInvitationStore();

const showNotificationsPanel = ref(false);

const { checkinAlerts } = useDeviceLocation();

// Toggle the notifications panel visibility
const toggleNotificationsPanel = (e) => {
  if (e) {
    e.notifHandled = true;
  }
  showNotificationsPanel.value = !showNotificationsPanel.value;
};

// Check if there are notifications
const hasNotifications = computed(() => {
  return checkinAlerts.value?.length > 0 || invites.value?.length > 0;
});

// Calculate the total number of notifications
const totalNotifications = computed(() => {
  return checkinAlerts.value?.length + invites.value?.length;
});

// Handle clicks outside the popover to close it
const handleClickOutside = (event) => {
  if (!event || event.notifHandled) {
    return;
  }
  if (!document.querySelector(".notifications-panel")?.contains(event.target)) {
    toggleNotificationsPanel();
  }
};

watch(showNotificationsPanel, () => {
  if (showNotificationsPanel.value) {
    nextTick(() => {
      document.addEventListener("click", handleClickOutside);
    });
  } else {
    document.removeEventListener("click", handleClickOutside);
  }
});
</script>

<style scoped>
.notifications-icon {
  position: relative;
  display: inline-block;
}

.badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.25rem;
  height: 20px;
  min-width: 20px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
