<!-- src/pages/ResetPasswordPage.vue -->
<template>
  <div class="reset-password">
    <h2>Reset Password</h2>
    <form @submit.prevent="resetPassword">
      <input
        v-model="password"
        type="password"
        placeholder="Enter new password"
        required
      />
      <button type="submit">Reset Password</button>
    </form>
    <div v-if="message">{{ message }}</div>
    <router-link to="/login" class="block mt-3 text-center"
      >Back to Login</router-link
    >
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute, RouterLink } from "vue-router";
import api from "../utils/api.js";

const password = ref("");
const message = ref("");
const route = useRoute();

const resetPassword = async () => {
  try {
    const token = route.params.token;
    const response = await api.post(`/auth/reset-password/${token}`, {
      password: password.value,
    });
    message.value = response.data.message;
  } catch (error) {
    message.value = "An error occurred. Please try again later.";
  }
};
</script>

<style scoped>
.reset-password {
  max-width: 300px;
  margin: auto;
  padding: 1rem;
}
</style>
