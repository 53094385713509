<template>
  <div class="pdf-container">
    <canvas ref="pdfCanvas" class="pdf-canvas"></canvas>
    <div class="pdf-navigation">
      <button @click="prevPage" :disabled="currentPage <= 1">Previous</button>
      <button @click="nextPage" :disabled="currentPage >= totalPages">
        Next
      </button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, defineProps } from "vue";
// import { getDocument } from "pdfjs-dist";

// import "./pdf.worker.js"; // Import the worker setup
// Load PDF.js from the CDN
const pdfjsLib = window["pdfjs-dist/build/pdf"];

// Set the worker source to the precompiled worker on the CDN
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js";

// Props for receiving the signed URL of the PDF
const props = defineProps({
  signedUrl: String,
});

const pdfDoc = ref(null);
const currentPage = ref(1);
const totalPages = ref(0);
const pdfCanvas = ref(null);

// Function to render a page in the PDF
const renderPage = async (pageNum) => {
  const canvas = pdfCanvas.value;
  if (canvas) {
    const page = await pdfDoc.value.getPage(pageNum);
    const viewport = page.getViewport({ scale: 1.5 });

    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into the canvas
    await page.render({
      canvasContext: context,
      viewport: viewport,
    }).promise;
  }
};

// Load PDF when the signed URL changes
const loadPdf = async () => {
  pdfDoc.value = await pdfjsLib.getDocument(props.signedUrl).promise;
  totalPages.value = pdfDoc.value.numPages;
  renderPage(currentPage.value);
};

// Watch for changes in the signed URL
watch(
  () => props.signedUrl,
  () => {
    if (props.signedUrl) {
      loadPdf();
    }
  }
);

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
    renderPage(currentPage.value);
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
    renderPage(currentPage.value);
  }
};

// Load the PDF initially
onMounted(() => {
  if (props.signedUrl) {
    loadPdf();
  }
});
</script>

<style scoped>
.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.pdf-navigation {
  margin-top: 10px;
  display: flex;
  gap: 0.5rem;
  height: 25px;
}

.pdf-canvas {
  border: 1px solid #000;
  height: 90%;
}
</style>
