import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useColorAndImage } from "../components/NoteComp/hooks/useColorAndImage";
import { useLabelStore } from "./useLabelStore";
import { dateToShortUrlFormat, shortUrlFormatToDate } from "../utils/helpers";
import store from "../store";

const { colors } = useColorAndImage();
const initialized = ref(false);
const showFilters = ref(false);

export function useFilters() {
  const { labels } = useLabelStore();
  const route = useRoute();
  const router = useRouter();
  //   const debounceTimeout = ref(null);

  const lastFilters = ref("");

  const filters = computed(() => store.state.filters);

  const sortOptions = [
    { label: "A-Z", value: "title_asc" },
    { label: "Z-A", value: "title_desc" },
    { label: "Newest", value: "created_at_desc" },
    { label: "Oldest", value: "created_at_asc" },
    { label: "Recently Updated", value: "updated_at_desc" },
  ];

  const toggleOptions = [
    { name: "pinned", label: "Pin" },
    { name: "hasFile", label: "File" },
    { name: "hasReminder", label: "Reminder" },
    { name: "hasTask", label: "Task" },
  ];

  const labelOptions = computed(() =>
    labels.value?.map((l) => ({
      label: l.name,
      value: l.name,
    }))
  );

  const ownerOptions = [
    { label: "Created by Me", value: "createdByMe" },
    { label: "Shared with Me", value: "sharedWithMe" },
    { label: "Friend 1", value: "friend1" },
    { label: "Friend 2", value: "friend2" },
  ];

  const colorOptions = [...colors.value];

  const cycleToggle = (toggle) => {
    const current = filters.value.toggles[toggle];
    store.mutations.updateModel("filters", "toggles", {
      ...filters.value.toggles,
      [toggle]: current === true ? false : current === false ? null : true,
    });
  };

  const toggleClass = (state) => {
    return {
      "p-button-success": state === true,
      "p-button-danger": state === false,
      "p-button-outlined": state === null,
    };
  };

  const isAnyFilterActive = computed(() => {
    return (
      filters.value.search ||
      filters.value.sort !== "created_at_desc" ||
      Object.values(filters.value.toggles).some((toggle) => toggle !== null) ||
      filters.value.labels.length > 0 ||
      filters.value.owner !== null ||
      filters.value.dateRange !== null ||
      filters.value.colors.length > 0
    );
  });

  const clearFilters = () => {
    store.mutations.setState("filters", {
      search: "",
      sort: "created_at_desc",
      toggles: {
        hasFile: null,
        hasReminder: null,
        hasTask: null,
        pinned: null,
      },
      labels: [],
      owner: null,
      dateRange: null,
      colors: [],
    });
    updateQueryParams();
  };

  const updateQueryParams = () => {
    const queryParams = {
      search: filters.value.search || undefined,
      sort:
        filters.value.sort !== "created_at_desc"
          ? filters.value.sort
          : undefined,
      hasFile:
        filters.value.toggles.hasFile !== null
          ? filters.value.toggles.hasFile
          : undefined,
      hasReminder:
        filters.value.toggles.hasReminder !== null
          ? filters.value.toggles.hasReminder
          : undefined,
      hasTask:
        filters.value.toggles.hasTask !== null
          ? filters.value.toggles.hasTask
          : undefined,
      pinned:
        filters.value.toggles.pinned !== null
          ? filters.value.toggles.pinned
          : undefined,
      labels: filters.value.labels.length
        ? filters.value.labels.join(",")
        : undefined,
      owner: filters.value.owner || undefined,
      dateRange: filters.value.dateRange
        ? filters.value.dateRange
            .map((d) =>
              d && d > " " ? dateToShortUrlFormat(new Date(d)) : null
            )
            .join(",")
        : undefined,
      colors: filters.value.colors.length
        ? filters.value.colors.join(",")
        : undefined,
    };

    if (JSON.stringify(queryParams) !== JSON.stringify(lastFilters.value)) {
      router.replace({ query: queryParams });
    }
    lastFilters.value = queryParams;
    initialized.value = true;
    // fetchNotes();
  };

  const applyFiltersFromQuery = () => {
    const { query } = route;

    const newFilters = {
      search: query.search || "",
      sort: query.sort || "created_at_desc",
      toggles: {
        hasFile:
          query.hasFile === "true"
            ? true
            : query.hasFile === "false"
            ? false
            : null,
        hasReminder:
          query.hasReminder === "true"
            ? true
            : query.hasReminder === "false"
            ? false
            : null,
        hasTask:
          query.hasTask === "true"
            ? true
            : query.hasTask === "false"
            ? false
            : null,
        pinned:
          query.pinned === "true"
            ? true
            : query.pinned === "false"
            ? false
            : null,
      },
      labels: query.labels ? query.labels.split(",") : [],
      owner: query.owner || null,
      dateRange: query.dateRange
        ? query.dateRange.split(",").map((d) => shortUrlFormatToDate(d))
        : null,
      colors: query.colors ? query.colors.split(",") : [],
    };

    if (JSON.stringify(newFilters) !== JSON.stringify(filters.value)) {
      store.mutations.setState("filters", newFilters);
    }
    if (!initialized.value) {
      updateQueryParams();
    }
  };

  const debounceUpdateQueryParams = () => {
    updateQueryParams();
    // if (debounceTimeout.value) {
    //   clearTimeout(debounceTimeout.value);
    // }
    // debounceTimeout.value = setTimeout(() => {
    //   updateQueryParams();
    // }, 100);
  };

  watch(filters, debounceUpdateQueryParams, { deep: true });

  watch(
    route,
    () => {
      applyFiltersFromQuery();
    },
    { deep: true, immediate: true }
  );

  function updateFilter(key, value) {
    store.mutations.updateModel("filters", key, value);
  }
  function setFiltersOpen(open) {
    showFilters.value = open;
  }
  return {
    filters,
    initialized,
    query: lastFilters,
    sortOptions,
    toggleOptions,
    labelOptions,
    ownerOptions,
    colorOptions,
    cycleToggle,
    toggleClass,
    isAnyFilterActive,
    clearFilters,
    updateFilter,
    showFilters,
    setFiltersOpen,
  };
}
