<template>
  <form @submit.prevent="handleSubmit" class="p-fluid">
    <div class="field">
      <label for="name">Name</label>
      <InputText id="name" v-model="formData.name" required />
    </div>
    <div class="field">
      <label for="organization">Organization</label>
      <InputText id="organization" v-model="formData.organization" />
    </div>
    <div class="field">
      <label for="title">Title</label>
      <InputText id="title" v-model="formData.title" />
    </div>
    <div class="field">
      <label for="photo">Photo URL</label>
      <InputText id="photo" v-model="formData.photo" />
    </div>

    <!-- Phone Numbers Section with Multi-Select Types -->
    <div class="field">
      <label>Phone Numbers</label>
      <div
        v-for="(phone, index) in formData.phoneNumbers"
        :key="index"
        class="p-fluid grid mb-1"
      >
        <div class="col-4">
          <MultiSelect
            :options="phoneTypes"
            optionLabel="label"
            optionValue="value"
            placeholder="Type"
            v-model="phone.type"
          />
        </div>
        <div class="col-6">
          <InputText placeholder="Phone Number" v-model="phone.number" />
        </div>
        <div class="col-2">
          <Button
            icon="pi pi-minus"
            class="p-button-danger p-button-rounded remove"
            @click="removePhoneNumber(index)"
          />
        </div>
      </div>
      <div />
      <Button
        label="Add Phone Number"
        icon="pi pi-plus"
        class="p-button-secondary mt-2"
        @click="addPhoneNumber"
      />
    </div>

    <!-- Emails Section with Multi-Select Types -->
    <div class="field">
      <label>Emails</label>
      <div
        v-for="(email, index) in formData.emails"
        :key="index"
        class="p-fluid grid mb-1"
      >
        <div class="col-4">
          <MultiSelect
            :options="emailTypes"
            optionLabel="label"
            optionValue="value"
            placeholder="Type"
            v-model="email.type"
            class="w-full"
          />
        </div>
        <div class="col-6 flex-1">
          <InputText placeholder="Email" v-model="email.email" class="w-full" />
        </div>

        <div class="col-2">
          <Button
            icon="pi pi-minus"
            class="p-button-danger p-button-rounded remove"
            @click="removeEmail(index)"
          />
        </div>
      </div>
      <div />
      <Button
        label="Add Email"
        icon="pi pi-plus"
        class="p-button-secondary mt-2"
        @click="addEmail"
      />
    </div>

    <!-- Addresses Section with Multi-Select Types -->
    <div class="field">
      <label>Addresses</label>

      <div
        v-for="(address, index) in formData.addresses"
        :key="index"
        class="p-fluid grid mb-1"
      >
        <div class="col-4">
          <MultiSelect
            class="w-full"
            :options="addressTypes"
            optionLabel="label"
            optionValue="value"
            placeholder="Type"
            v-model="address.type"
          />
        </div>
        <div class="col-6">
          <textarea
            class="w-full"
            placeholder="Address"
            v-model="address.address"
          />
        </div>

        <div class="col-2">
          <Button
            icon="pi pi-minus"
            class="p-button-danger p-button-rounded remove"
            @click="removeAddress(index)"
          />
        </div>
      </div>
      <div />
      <Button
        label="Add Address"
        icon="pi pi-plus"
        class="p-button-secondary mt-2"
        @click="addAddress"
        text
      />
    </div>

    <!-- Notes Section -->
    <div class="field">
      <label for="notes">Notes</label>
      <div />
      <Textarea
        id="notes"
        v-model="formData.notes"
        placeholder="Add notes here"
        class="w-full"
      />
    </div>
  </form>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, computed } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import MultiSelect from "primevue/multiselect";
import { useContactStore } from "../hooks/useContactStore";

const { contacts } = useContactStore();

const props = defineProps({
  contact: Object,
  submit: Boolean,
});
const localContact = computed(() =>
  contacts.value?.find((c) => c.id === props.contact?.id)
);

const emit = defineEmits(["submit", "close"]);

// Common type options for MultiSelect
const phoneTypes = [
  { label: "Mobile", value: "mobile" },
  { label: "Home", value: "home" },
  { label: "Work", value: "work" },
  { label: "Other", value: "other" },
  { label: "Preferred", value: "pref" },
];
const emailTypes = phoneTypes;
const addressTypes = phoneTypes;

const formData = ref({
  name: "",
  organization: "",
  title: "",
  photo: "",
  phoneNumbers: [{ number: "", type: [] }],
  emails: [{ email: "", type: [] }],
  addresses: [{ address: "", type: [] }],
  notes: "",
});

// Watch for contact prop changes to populate formData for editing
watch(
  () => localContact,
  (newContact) => {
    if (newContact) {
      console.log("newContact", newContact);
      formData.value = {
        name: newContact.value.name || "",
        organization: newContact.value.organization || "",
        title: newContact.value.title || "",
        photo: newContact.value.photo || "",
        phoneNumbers: newContact.value.phoneNumbers
          ? [...newContact.value.phoneNumbers]
          : [{ number: "", type: [] }],
        emails: newContact.value.emails
          ? [...newContact.value.emails]
          : [{ email: "", type: [] }],
        addresses: newContact.value.addresses
          ? [...newContact.value.addresses]
          : [{ address: "", type: [] }],
        notes: newContact.value.notes || "",
      };
    }
  },
  { immediate: true }
);

watch(
  () => props.submit,
  (isSubmitting) => {
    if (isSubmitting) {
      handleSubmit();
    }
  }
);
// Handle form submission
const handleSubmit = () => {
  emit("submit", { ...formData.value });
};

// Methods to add and remove phone numbers, emails, and addresses
const addPhoneNumber = () =>
  formData.value.phoneNumbers.push({ number: "", type: [] });
const removePhoneNumber = (index) =>
  formData.value.phoneNumbers.splice(index, 1);

const addEmail = () => formData.value.emails.push({ email: "", type: [] });
const removeEmail = (index) => formData.value.emails.splice(index, 1);

const addAddress = () =>
  formData.value.addresses.push({ address: "", type: [] });
const removeAddress = (index) => formData.value.addresses.splice(index, 1);
</script>

<style>
/* Custom styling for the form */
.field {
  margin-bottom: 1rem;
}
.remove {
  height: 25px !important;
  width: 25px !important;
}
</style>
