import { computed } from "vue";
import store from "../store";

export function useSidebarState() {
  const isSidebarOpen = computed(() => store.state.isSidebarOpen);

  const toggleSidebar = () => {
    console.log("toggleSidebar", !isSidebarOpen.value);
    store.mutations.setState("isSidebarOpen", !isSidebarOpen.value);
  };

  return {
    isSidebarOpen,
    toggleSidebar,
  };
}
