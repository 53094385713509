<template>
  <div class="flex flex-column align-items-center p-4">
    <h2 class="text-center mb-4 heading">Terms of Use</h2>
    <div class="w-full md:w-8 surface-card p-4 shadow-2 border-round">
      <h3>Effective Date: 09/01/2024</h3>

      <h4>Introduction</h4>
      <p>
        Welcome to Storatic! We provide note-taking, task management, and
        collaboration tools to help you manage your personal and professional
        life. These Terms of Use (“Terms”) govern your access to and use of all
        Storatic services, including our website (https://storatic.com), mobile
        applications, subdomains like https://api.storatic.com and
        https://files.storatic.com, and any other associated services
        (collectively, the “Services”). By accessing or using the Services, you
        agree to these Terms. If you do not agree, please do not use the
        Services.
      </p>

      <h4>1. Definitions</h4>
      <p>
        “We,” “Us,” “Our” refers to Storatic, including its employees,
        contractors, officers, and directors. “You” or “User” refers to the
        individual or entity using the Services. “Content” refers to any files,
        notes, tasks, reminders, or other information uploaded, created, or
        shared within the Services. “Shared Content” refers to content that a
        user has chosen to share with another user directly.
      </p>

      <h4>2. Eligibility to Use</h4>
      <p>
        By using the Services, you affirm that you are at least 18 years old or
        have parental or guardian consent if under 18. Users under the age of 13
        are not permitted to use the Services. The Services are currently
        available for use in the United States. By using the Services, you
        affirm that your use complies with all local, state, and national laws
        applicable to you.
      </p>

      <h4>3. Account Creation and Security</h4>
      <p>
        To access certain features of the Services, you must create an account.
        You agree to provide accurate, up-to-date information during the
        registration process. Failure to do so may result in termination or
        suspension of your account. You are responsible for safeguarding your
        account credentials and for all activities under your account. Notify us
        immediately of any unauthorized access or breach of security. Your
        account can be accessed across devices, syncing your content. By
        accessing your account on multiple devices, you agree to allow such
        synchronization.
      </p>

      <h4>4. User Responsibilities and Acceptable Use</h4>
      <p>
        You agree to use the Services only for lawful purposes and in accordance
        with these Terms. You agree not to engage in any activity that violates
        any applicable law, including intellectual property and privacy laws, or
        upload content that is defamatory, obscene, or otherwise objectionable.
        You also agree not to misrepresent your identity or affiliation,
        interfere with the Services, or use automated systems to access or
        manipulate the Services.
      </p>

      <h4>5. Ownership and License of Content</h4>
      <p>
        You retain ownership of any content that you create, upload, or share
        through Storatic. By using the Services, you grant Storatic a worldwide,
        non-exclusive, royalty-free license to store, process, and transmit your
        content solely for the purpose of providing the Services. You
        acknowledge that recipients of your shared content may retain, modify,
        or share it even after you delete it from your account. You represent
        that you have all necessary rights to upload, share, or distribute your
        content and that it does not infringe upon any third-party rights,
        including intellectual property rights.
      </p>

      <h4>6. Data and Content Removal</h4>
      <p>
        Users can flag content that they believe violates these Terms. We
        reserve the right to remove any content that violates these Terms or
        applicable law. Users can delete content from their accounts at any
        time. Once deleted, content is permanently removed from our servers.
        Storatic reserves the right to delete inactive accounts after 12 months
        of inactivity, with prior notification.
      </p>

      <h4>7. Data Storage and Retention</h4>
      <p>
        Your content is stored securely using AWS infrastructure. We employ
        encryption in transit and at rest to protect your data. Your data will
        be retained as long as your account remains active, or until you delete
        the data or your account. Storatic is not liable for any loss of data,
        and you are encouraged to maintain your own backups of important data.
      </p>

      <h4>8. Third-Party Services</h4>
      <p>
        We use AWS for file storage and LocationIQ for address typeahead
        functionality. By using the Services, you agree to their respective
        privacy policies and terms. AWS Privacy Policy is available at [AWS
        Privacy](https://aws.amazon.com/privacy), and LocationIQ Privacy Policy
        is available at [LocationIQ Privacy
        Policy](https://us1.locationiq.com/privacy).
      </p>

      <h4>9. Privacy</h4>
      <p>
        Your privacy is important to us. Please review our Privacy Policy for
        detailed information about how we collect, use, and protect your data.
      </p>

      <h4>10. Disclaimers and Limitation of Liability</h4>
      <p>
        The Services are provided "as is" without any warranties of any kind.
        Storatic does not guarantee that the Services will meet your
        requirements or that the operation of the Services will be uninterrupted
        or error-free. Storatic is not liable for any indirect, incidental,
        special, consequential, or punitive damages arising from your use of the
        Services.
      </p>

      <h4>11. Indemnification</h4>
      <p>
        You agree to indemnify, defend, and hold harmless Storatic from any
        claims, liabilities, damages, losses, or expenses arising from your use
        of the Services, violation of these Terms, or infringement of any
        third-party rights.
      </p>

      <h4>12. Termination</h4>
      <p>
        Storatic reserves the right to suspend or terminate your account or
        access to the Services at our discretion. You may terminate your account
        at any time by contacting us, and your account and associated data will
        be deleted. Upon termination, your right to use the Services ceases
        immediately.
      </p>

      <h4>13. Changes to the Terms</h4>
      <p>
        We may modify these Terms at any time. If we make significant changes,
        we will notify you via email or through the Services. Your continued use
        of the Services constitutes acceptance of the revised terms.
      </p>

      <h4>14. Governing Law and Dispute Resolution</h4>
      <p>
        These Terms are governed by the laws of the United States. Any disputes
        arising from these Terms or your use of the Services will be resolved
        through binding arbitration in [City, State]. You waive the right to
        participate in a class-action lawsuit.
      </p>

      <h4>15. Contact Information</h4>
      <p>
        For questions or concerns about these Terms, please contact us at
        support@storatic.com.
      </p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";
import { useSidebarState } from "../hooks/useSidebarState";
const { isSidebarOpen, toggleSidebar } = useSidebarState();

let wasOpened = false;
onMounted(() => {
  wasOpened = isSidebarOpen.value;
  if (wasOpened) {
    toggleSidebar();
  }
});
onUnmounted(() => {
  if (wasOpened && !isSidebarOpen.value) {
    toggleSidebar();
  }
});
</script>

<style scoped>
.heading {
  color: var(--corp-text-color);
}
.shadow-2 {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.border-round {
  border-radius: 0.5rem;
}

h2,
h3,
h4 {
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
  line-height: 1.5;
}
</style>
