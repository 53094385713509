import axios from "axios";
import store from "../store";
import { useAuthStore } from "../hooks/useAuthStore";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL || "http://localhost:3000",
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to add the authorization token if available
api.interceptors.request.use(
  async (config) => {
    if (config.url !== "/auth/refresh-token") {
      const { validateTokenRefresh } = useAuthStore();
      if (store?.state?.refreshToken) {
        await validateTokenRefresh(
          store?.state?.token,
          store?.state?.refreshToken,
          store?.state?.user
        );
      }
      if (store?.state?.token) {
        config.headers.Authorization = `Bearer ${store?.state?.token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
