<template>
  <i class="pi pi-check"></i>
</template>

<script setup></script>

<style scoped>
.pi {
  font-size: 16px; /* Adjust the size as needed */
  color: inherit; /* Adjust the color based on your design */
  /* background-color: #4caf50; Optional: background color for the check icon */
  border-radius: 50%;
  padding: 4px;
  display: block;
  margin: auto;
  cursor: pointer;
}
</style>
