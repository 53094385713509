<template>
  <div class="icon" v-if="noteId">
    <i
      class="pi pi-comment"
      @click="router.push(`/note/${noteId}/comments`)"
      v-tooltip.bottom="'Comments'"
    ></i>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

// eslint-disable-next-line no-unused-vars
const props = defineProps({ noteId: String });
</script>

<style scoped>
.icon {
  cursor: pointer;
}
</style>
