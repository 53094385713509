<template>
  <DataTable
    :value="contacts"
    selectionMode="single"
    @selection-change="onRowSelect"
    dataKey="id"
    responsiveLayout="scroll"
    class="w-full overflow-auto"
  >
    <Column field="name" header="Name">
      <template #body="slotProps">
        <div class="flex">
          <!-- <div
            v-if="slotProps.data.photo"
            :style="{
              backgroundImage: `url('https://placehold.co/600x400/EEE/31343C')`,
            }"
            alt="User Profile"
            class="profile-pic mr-2"
          /> -->
          <img
            class="profile-pic mr-2"
            :src="
              slotProps.data.photo
                ? `data:image/jpeg;base64,${slotProps.data.photo}`
                : `https://placehold.co/400x400/EEE/31343C?font=open-sans&text=${
                    slotProps.data.name?.charAt(0) || 'U'
                  }`
            "
            aria-label="User Profile"
          />

          <div class="text-ellipse">
            <div class="font-bold">{{ slotProps.data.name }}</div>
            <div class="text-xs">
              {{ slotProps.data.title }}
            </div>
            <div class="text-xs">{{ slotProps.data.organization }}</div>
          </div>
        </div>
      </template>
    </Column>

    <Column header="Emails">
      <template #body="slotProps">
        <div v-if="slotProps.data.emails">
          <div v-for="(emailData, index) in slotProps.data.emails" :key="index">
            <!-- Render Chip for each type -->
            <Chip
              v-for="(type, typeIndex) in Array.isArray(emailData.type)
                ? emailData.type
                : [emailData.type]"
              :key="typeIndex"
              :label="type"
              class="mr-2 label"
            />
            <br />
            <!-- Render Email Address -->
            <span>{{ emailData.email }}</span>
            <br /><br />
          </div>
        </div>
        <div v-else>N/A</div>
      </template>
    </Column>
    <Column header="Phone Numbers">
      <template #body="slotProps">
        <div v-if="slotProps.data.phoneNumbers">
          <div
            v-for="(phoneData, index) in slotProps.data.phoneNumbers"
            :key="index"
          >
            <Chip
              v-for="(type, typeIndex) in Array.isArray(phoneData.type)
                ? phoneData.type
                : [phoneData.type]"
              :key="typeIndex"
              :label="type"
              class="mr-2 label"
            />
            <br />
            <span>{{ phoneData.number }}</span>
            <br /><br />
          </div>
        </div>
        <div v-else>N/A</div>
      </template>
    </Column>
    <Column header="Addresses">
      <template #body="slotProps">
        <div v-if="slotProps.data.addresses">
          <div
            v-for="(addressData, index) in slotProps.data.addresses"
            :key="index"
          >
            <Chip
              v-for="(type, typeIndex) in Array.isArray(addressData.type)
                ? addressData.type
                : [addressData.type]"
              :key="typeIndex"
              :label="type"
              class="mr-2 label"
            />
            <br />
            <span>{{
              Array.isArray(addressData.address)
                ? addressData.address.join(" ")
                : addressData.address
            }}</span>
            <br /><br />
          </div>
        </div>
        <div v-else>N/A</div>
      </template>
    </Column>
    <Column header="Actions" bodyClass="actions-column">
      <template #body="{ data }">
        <Button
          label="View"
          icon="pi pi-eye"
          @click="$emit('selectContact', data)"
          class="p-button-text"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import Button from "primevue/button";
import Chip from "primevue/chip";
import Column from "primevue/column";
import DataTable from "primevue/datatable";

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  contacts: Array,
});

const emit = defineEmits(["selectContact"]);

function onRowSelect(event) {
  emit("selectContact", event.data);
}
</script>

<style>
.actions-column {
  text-align: center;
  width: 150px;
}
.label {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.8rem;
  background-color: var(--corp-slate-500) !important;
  color: #fff !important;
}
</style>
