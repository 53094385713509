<template>
  <div class="pt-0 pb-8 px-4 w-full flex-1">
    <div
      class="flex gap-1 mb-3 fixed top-0 z-2 mt-6 py-2 w-full pr-6 contact-header"
    >
      <Button label="New Contact" icon="pi pi-plus" @click="openCreateForm" />
      <Button
        v-if="deviceContactsSupported"
        label="Import Contacts"
        icon="pi pi-mobile"
        class="p-button-secondary"
        @click="importFromDeviceContacts"
      />
      <Button
        label="Import (vCard)"
        icon="pi pi-upload"
        class="p-button-secondary"
        @click="promptForVCardFile"
      />
      <div class="flex-1" />
      <!-- <Button
      v-if="contacts.length"
      label="Export Contacts (CSV)"
      icon="pi pi-file"
      class="p-button-success"
      @click="exportToCSV"
    /> -->
      <Button
        v-if="contacts.length"
        label="Export (vCard)"
        icon="pi pi-file"
        class="p-button-success"
        @click="exportToVCard"
      />
    </div>

    <div class="mt-8" />
    <ContactTable
      :contacts="contacts"
      @selectContact="viewContact"
      class="pt-5"
    />

    <Dialog
      header=""
      :visible="showForm"
      style="width: 400px"
      modal
      :closable="false"
    >
      <template #header
        ><Button @click="closeForm" icon="pi pi-times" text />
        <div class="flex-1" />
        <Button
          :disabled="submitForm"
          label="Save"
          @click="submitForm = true"
          text
      /></template>
      <ContactForm
        :contact="selectedContact"
        :submit="submitForm"
        @submit="handleFormSubmit"
        @close="closeForm"
      />
    </Dialog>
    <Dialog
      header=""
      :visible="selectedContact && !showForm"
      v-if="selectedContact && !showForm"
      style="width: 400px"
      modal
      :closable="false"
      :sm:position="bottom"
    >
      <template #header>
        <Button @click="clearSelection" icon="pi pi-times" text />
        <div class="flex-1" />
        <Button @click="openEditForm" label="Edit" class="mr-1" text />
        <Button
          @click="() => deleteContactHandler(selectedContact.id)"
          label="Delete"
          text
          severity="danger"
        />
      </template>
      <ContactDetail
        :contact="selectedContact"
        @edit="openEditForm"
        @delete="deleteContactHandler"
        @back="clearSelection"
      />
    </Dialog>
    <input
      type="file"
      ref="fileInput"
      accept=".vcf"
      style="display: none"
      @change="handleFileUpload"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import { useContactStore } from "../hooks/useContactStore";
import ContactTable from "../components/ContactTable.vue";
import ContactForm from "../components/ContactForm.vue";
import ContactDetail from "../components/ContactDetail.vue";
import { useSidebarState } from "../hooks/useSidebarState";

const { isSidebarOpen, toggleSidebar } = useSidebarState();
const {
  contacts,
  fetchContacts,
  createContact,
  updateContact,
  deleteContact,
  importFromDeviceContacts,
  deviceContactsSupported,
  importVCard,
  //   exportContactsAsCSV,
  exportContactsAsVCard,
} = useContactStore();

const selectedContact = ref(null);
const showForm = ref(false);
const fileInput = ref(null);
const submitForm = ref(false);

const openCreateForm = () => {
  selectedContact.value = null;
  showForm.value = true;
};

const openEditForm = () => {
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};

const viewContact = (contact) => {
  selectedContact.value = contact;
};

const clearSelection = () => {
  selectedContact.value = null;
};

const handleFormSubmit = async (contactData) => {
  submitForm.value = false;
  if (selectedContact.value) {
    await updateContact(selectedContact.value.id, contactData);
  } else {
    await createContact(contactData);
  }
  await fetchContacts();
  closeForm();
};

const deleteContactHandler = async (contactId) => {
  await deleteContact(contactId);
  clearSelection();
  await fetchContacts();
};

const promptForVCardFile = () => {
  fileInput.value.click();
};

const handleFileUpload = async (event) => {
  const file = event.target.files[0];
  if (file) {
    const vCardData = await file.text();
    await importVCard(vCardData);
    await fetchContacts();
  }
};

// const exportToCSV = async () => {
//   await exportContactsAsCSV();
// };

const exportToVCard = async () => {
  await exportContactsAsVCard();
};
let wasOpened = false;
onMounted(() => {
  wasOpened = isSidebarOpen.value;
  if (wasOpened) {
    toggleSidebar();
  }
});
onUnmounted(() => {
  if (wasOpened && !isSidebarOpen.value) {
    toggleSidebar();
  }
});
</script>

<style scoped>
.contact-header {
  background-color: var(--app-back);
}
</style>
