<template>
  <div class="color-icon">
    <ColorPaletteIcon @click="toggleColorPaletteMenu" />
    <div v-if="showColorPaletteMenu" class="color-palette-menu">
      <div class="flex gap-1 flex-wrap w-full">
        <!-- Clear Option -->

        <!-- Color Swatches -->
        <div
          class="color-swatch"
          v-for="color in colors"
          :key="color.name"
          :style="{ backgroundColor: color.hex }"
          @click="selectColor(color)"
          v-tooltip.bottom="color.name"
        >
          <CheckIcon v-if="selectedColor?.name === color.name" />
        </div>
      </div>
      <div class="flex w-full">
        <!-- <div class="clear-option" @click="selectColor(null)">
          <ClearIcon /> Clear
        </div> -->
        <div class="flex-1" />
        <div class="" @click="toggleColorPaletteMenu">Done</div>
      </div>
      <!-- <div class="flex gap-1">
        <div class="clear-option" @click="selectImage(null)">
          <ClearIcon />
        </div>

        <div
          class="image-swatch"
          v-for="image in images"
          :key="image.name"
          :style="{ backgroundImage: `url(${image.url})` }"
          @click="selectImage(image)"
          v-tooltip.bottom="image.name"
        >
          <CheckIcon v-if="selectedImage?.name === image.name" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
import ColorPaletteIcon from "./ColorPaletteIcon.vue";
// import ClearIcon from "./ClearIcon.vue"; // Add Clear Icon
import CheckIcon from "./CheckIcon.vue"; // Add Checkmark Icon

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  colors: Array,
  images: Array,
  selectedColor: String,
  selectedImage: String,
  selectColor: Function,
  selectImage: Function,
});

const showColorPaletteMenu = ref(false);

const toggleColorPaletteMenu = (e) => {
  if (e) {
    e.colorHandled = true;
  }
  //   e?.preventDefault();
  //   e?.stopPropagation();
  showColorPaletteMenu.value = !showColorPaletteMenu.value;
};

const handleClickOutside = (event) => {
  if (event && event.colorHandled) {
    return;
  }
  if (
    showColorPaletteMenu.value &&
    !document.querySelector(".color-palette-menu")?.contains(event.target)
  ) {
    toggleColorPaletteMenu();
  }
};

watch(showColorPaletteMenu, () => {
  if (showColorPaletteMenu.value === true) {
    document.addEventListener("click", handleClickOutside);
  } else {
    document.removeEventListener("click", handleClickOutside);
  }
});
</script>

<style scoped>
.color-icon {
  position: relative;
}

.color-palette-menu {
  position: absolute;
  right: -17px;
  background: var(--flyout-back);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px 4px;
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 200px;
}
/* 
.clear-option {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
} */

.color-swatch,
.image-swatch {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-swatch {
  border: 1px solid #ccc;
}

.image-swatch {
  background-size: cover;
  background-position: center;
}

/* Checkmark Icon Style */
.check-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-icon svg {
  width: 8px;
  height: 8px;
}

.clear-option,
.color-swatch,
.image-swatch {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
