import { computed, ref } from "vue";
import store from "../store";
import api from "../utils/api";

export function useReminderStore() {
  const isLoading = ref(false);
  const reminders = computed(() => store.state.reminders);

  async function createReminder(reminderData) {
    try {
      const response = await api.post("/api/reminders", reminderData);
      const note = store.state.notes?.find(
        (note) => note.id === response.data.noteId
      );
      let updatedReminders;
      if (note) {
        updatedReminders = [...note.reminders, response.data];
        store.mutations.updateModel("notes", response.data.noteId, {
          ...note,
          reminders: updatedReminders,
        });
      }
      return updatedReminders || [response.data];
    } catch (error) {
      console.error("Error creating reminder:", error);
      throw error;
    }
  }

  async function updateReminder(id, reminderData) {
    try {
      const response = await api.put(`/api/reminders/${id}`, reminderData);
      const note = store.state.notes?.find(
        (note) => note.id === response.data.noteId
      );
      let updatedReminders;
      if (note) {
        const index = note?.reminders
          ?.map((t) => t.id)
          ?.indexOf(response.data.id);
        if (index >= 0) {
          updatedReminders = [...note.reminders];
          updatedReminders[index] = response.data;
          store.mutations.updateModel("notes", response.data.noteId, {
            ...note,
            reminders: updatedReminders,
          });
        }
      }
      return updatedReminders;
    } catch (error) {
      console.error("Error updating reminder:", error);
      throw error;
    }
  }

  async function deleteReminder(id, noteId) {
    try {
      await api.delete(`/api/reminders/${id}`);
      const note = store.state.notes?.find((note) => note.id === noteId);
      if (note) {
        store.mutations.updateModel("notes", {
          ...note,
          reminders: note.reminders.filter((t) => t.id !== id),
        });
      }
    } catch (error) {
      console.error("Error deleting reminder:", error);
      throw error;
    }
  }

  return {
    reminders,
    isLoading,
    createReminder,
    updateReminder,
    deleteReminder,
  };
}
