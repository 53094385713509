<template>
  <div class="flex flex-column align-items-center p-4">
    <h2 class="text-center mb-4">Admin Panel</h2>
    <Button
      label="ReLoad Users"
      icon="pi pi-refresh"
      @click="fetchUsers"
      class="mb-4"
    />
    <ul class="list-none p-0 w-full md:w-6">
      <li
        v-for="user in users"
        :key="user.id"
        class="p-2 mb-2 surface-card shadow-1 border-round"
      >
        <div class="flex align-items-center justify-content-between">
          <span>{{ user.email }} - {{ user.role.name }}</span>

          <Dropdown
            v-if="user.id !== authedUserId"
            v-model="user.roleId"
            @change="(role) => updateUserRole(role, user)"
            :options="roles"
            optionLabel="name"
            optionValue="id"
            class="w-6 md:w-3"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import api from "../utils/api.js";
import store from "../store";

const authedUserId = computed(() => store.user?.id);
const users = ref([]);
const roles = ref([]);

const fetchUsers = async () => {
  try {
    const response = await api.get("/admin/users");
    users.value = response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

const fetchRoles = async () => {
  try {
    const response = await api.get("/admin/roles"); // Adjusted API path for admin namespace
    roles.value = response.data;
  } catch (error) {
    console.error("Error fetching roles:", error);
  }
};

const updateUserRole = async (role, user) => {
  try {
    await api.post("/admin/assign-role", {
      userId: user.id,
      roleId: role.value,
    });
  } catch (error) {
    console.error("Error updating user role:", error);
  }
};

fetchRoles();
fetchUsers();
</script>

<style scoped>
.shadow-1 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shadow-2 {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.border-round {
  border-radius: 0.5rem;
}
</style>
