import { computed } from "vue";
import { Geolocation } from "@capacitor/geolocation";
import {
  handlePositionUpdate,
  isNative,
  // manuallySetLastKnownPosition,
} from "../utils/locationHelper"; // Import the shared helper
import store from "../store";

export function useDeviceLocation() {
  const MIN_POLLING_INTERVAL = 30000; // Start polling every 30 seconds
  const MAX_POLLING_INTERVAL = 30000; // Max backof
  let pollingInterval = MIN_POLLING_INTERVAL;
  let isTracking = false;
  let pollingTimeout = null; // Timeout reference for polling
  let checkinAlerts = computed(() => store.state.checkinAlerts);

  async function requestLocationPermissions() {
    if (isNative) {
      // First, request "While Using the App" (When In Use) permission
      const permResult = await Geolocation.requestPermissions({
        permissions: ["location"],
      });

      console.log("permResult (When In Use)", permResult);

      if (permResult.location === "denied") {
        console.error("Location permissions denied.");
        return false;
      }

      // Check if you need "Always Allow" permission
      if (permResult.location === "granted") {
        // Here, you can either wait for some background feature to trigger
        // Or prompt the user manually to go to settings to enable "Always Allow"
        const alwaysPermissionResult = await Geolocation.requestPermissions({
          permissions: ["locationAlways"],
        });

        console.log("permResult (Always)", alwaysPermissionResult);

        if (alwaysPermissionResult.location === "denied") {
          console.error("Location 'Always Allow' denied.");
          // return false;
        }
      }
    }
    return true;
  }

  // Function to poll location with backoff
  async function pollLocation() {
    if (!isTracking) return; // Stop polling if tracking is not active

    try {
      if (isNative) {
        // Use Capacitor Geolocation for native platforms
        const position = await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
        });
        handlePositionUpdate(position, null);
      } else if (navigator.geolocation) {
        // Use browser's geolocation for web platforms
        navigator.geolocation.getCurrentPosition(
          (position) => {
            handlePositionUpdate({ coords: position.coords }, null);
          },
          (err) => {
            console.error("Error getting location (web):", err);
          },
          {
            enableHighAccuracy: true,
          }
        );
      } else {
        throw new Error("Geolocation not supported on this platform.");
      }

      // Reset interval to minimum after successful update
      pollingInterval = MIN_POLLING_INTERVAL;
    } catch (err) {
      console.error("Error getting location:", err);

      // Increase the polling interval using backoff logic
      pollingInterval = Math.min(pollingInterval * 2, MAX_POLLING_INTERVAL);
    }

    // Schedule the next poll with the updated interval
    pollingTimeout = setTimeout(pollLocation, pollingInterval);
  }

  async function startLocationTracking() {
    if (isTracking) return;

    const permissionsGranted = await requestLocationPermissions();
    if (!permissionsGranted) {
      console.error("Location permissions not granted. Stopping tracking.");
      return;
    }

    isTracking = true;

    if (isNative) {
      document.addEventListener("significantLocationChange", async (event) => {
        const locationData = event.detail;
        handlePositionUpdate(
          {
            coords: {
              latitude: locationData.latitude,
              longitude: locationData.longitude,
              accuracy: locationData.accuracy,
            },
          },
          null,
          { skipNotify: true }
        );
      });
    }

    // Start initial poll
    await pollLocation();
  }

  function stopLocationTracking() {
    if (!isTracking) return;

    isTracking = false;

    if (pollingTimeout) {
      clearTimeout(pollingTimeout); // Stop the polling
    }
  }

  return {
    startLocationTracking,
    stopLocationTracking,
    isTracking,
    checkinAlerts,
  };
}
