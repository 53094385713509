import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import api from "../utils/api";

export async function usePushNotifications() {
  // Add listeners for push events
  const addListeners = async () => {
    // Handle the push notification when received
    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push received: ", notification);
      }
    );

    // Handle registration errors
    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
    });

    // Handle when the user taps on a notification
    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        if (notification.notification?.data?.noteId) {
          window?.router?.push(
            `/note/${notification.notification?.data?.noteId}`
          );
        }
      }
    );

    // Handle successful registration
    await PushNotifications.addListener("registration", (token) => {
      console.log("Push registration success, token:", token.value);
      api.post("/push", {
        token: token.value,
        platform: Capacitor.getPlatform() === "ios" ? "APN" : "FCM",
      });
    });
  };

  // Request push notification permissions and register
  const requestPushPermissionsAndRegister = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive === "granted") {
      // Register with the push notification service
      try {
        await PushNotifications.register();
        console.log("PushNotifications.register() successfully called");
      } catch (err) {
        console.error("Error during PushNotifications.register():", err);
      }
    } else {
      console.error("Push notification permission denied");
    }
  };

  // Initialize the listeners and request permissions
  await addListeners();
  await requestPushPermissionsAndRegister();
}
