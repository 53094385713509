import { computed, ref } from "vue";
import store from "../store";
import api from "../utils/api";

export function useActivityStore() {
  const isLoading = ref(false);
  const activities = computed(() => store.state.activities);

  async function fetchactivities({ noteId }) {
    try {
      isLoading.value = true;
      const response = await api.get(`/api/notes/${noteId}/activity`);
      const acts = response.data?.map((d) => {
        return { ...d, output: generateReadableArray(d.details) };
      });
      store.mutations.setState("activities", acts);
    } catch (error) {
      console.error("Error fetching activities:", error);
    } finally {
      isLoading.value = false;
    }
  }
  const generateReadableArray = (data) => {
    const result = [];

    // Iterate over each key-value pair in the object
    for (const [key, value] of Object.entries(data)) {
      const newValue = value.newValue;
      const oldValue = value.oldValue;

      // Skip null or empty values
      if (newValue === null || newValue === "") {
        continue;
      }

      // Add the key and value as an object to the result array
      result.push({
        label: formatKey(key), // Human-readable key
        oldValue: formatValue(key, oldValue), // Formatted value
        newValue: formatValue(key, newValue),
      });
    }

    return result;
  };

  // Helper function to format the key into a readable label
  const formatKey = (key) => {
    switch (key) {
      case "userId":
        return "User ID";
      case "shareUrl":
        return "Share URL";
      default:
        return key.charAt(0).toUpperCase() + key.slice(1); // Capitalize the first letter
    }
  };

  // Helper function to format the value (you can customize this further)
  const formatValue = (key, value) => {
    if (key === "shareUrl") {
      return `<a href="${value}" target="_blank">${value}</a>`; // Return formatted HTML link
    }
    return value; // For other fields, return as-is
  };
  return {
    activities,
    isLoading,
    fetchactivities,
  };
}
