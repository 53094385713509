import { ref, watch, onMounted, onUnmounted } from "vue";
import store from "../store";
import { useNotify, playDing } from "../hooks/useNotify";
import { useAuthStore } from "../hooks/useAuthStore";
import { useDeviceId } from "./useDeviceId";
import { useRouter } from "vue-router";
import { format } from "date-fns";
import { useColorAndImage } from "../components/NoteComp/hooks/useColorAndImage";

const truncateString = (str, num) => {
  return str.length > num ? str.slice(0, num) + "..." : str;
};

// Define a reference to store the WebSocket connection
const websocket = ref(null);
const isConnected = ref(false);

export function useWebSocket() {
  const { notify } = useNotify();
  const router = useRouter();
  const { deviceId } = useDeviceId(); // Get the deviceId
  const { isAuthenticated, token } = useAuthStore();
  const connectWebSocket = (url, authToken) => {
    if (websocket.value && websocket.value.readyState === WebSocket.OPEN) {
      return; // Prevent creating a new connection if already open
    }

    // Create a new WebSocket connection
    websocket.value = new WebSocket(url);

    // Handle connection open
    websocket.value.onopen = () => {
      isConnected.value = true;
      console.log("WebSocket connection opened");

      // Send authentication token after connection is opened
      if (websocket.value.readyState === WebSocket.OPEN) {
        websocket.value.send(
          JSON.stringify({
            action: "authenticate",
            token: authToken,
            deviceId: deviceId.value,
          })
        );
      }
    };

    // Handle incoming messages
    websocket.value.onmessage = (event) => {
      try {
        const resdata = event.data;
        const { type, id, data, event: eventType } = JSON.parse(resdata);
        // Handle the event by updating the store based on the event type
        handleEvent(eventType, type, id, data);
      } catch (e) {
        console.log(e);
      }
    };

    // Handle connection close
    websocket.value.onclose = () => {
      isConnected.value = false;
      console.log("WebSocket connection closed");
    };

    // Handle errors
    websocket.value.onerror = (error) => {
      console.error("WebSocket connection error:", error);
      isConnected.value = false;
    };
  };

  const handleEvent = (event, type, id, data) => {
    console.log("handleEvent", event, type, id, data);
    switch (event) {
      case "notify":
        playDing();
        data.forEach((event) => {
          const { noteStyle } = useColorAndImage(event);

          const title = "New Event"; // Example title, you can adjust as needed
          const message = event.content
            ? truncateString(event.content, 200)
            : ""; // Event content
          const data = { noteId: event.note_id }; // Data for the notification

          notify(
            title,
            message,
            data, // Additional data for the notification
            {
              severity: "secondary",
              summary: message,
              color: noteStyle,
              start: event.instance_start,
              detail: format(new Date(event.instance_start), "PPp"),
              onClick: () => {
                console.log("Notification clicked");
                // Navigate to a different route when the notification is clicked
                router.push({ path: `/note/${event.note_id}` });
              },
            }
          );
        });
        break;
      case "create":
        if (type === "notes") {
          console.log("handleEvent", event, type, id, data);
          store.mutations.addModel(type, data);
        }
        break;
      case "update":
        if (type === "notes") {
          console.log("handleEvent", event, type, id, data);
          let existingModel = store.state?.notes.find((n) => n.id === id);
          store.mutations.updateModel(type, id, { ...existingModel, ...data });
        }
        break;
      case "delete":
        if (type === "notes") {
          console.log("handleEvent", event, type, id, data);
          store.mutations.removeModel(type, id);
        }
        break;
      default:
        console.warn(`Unknown event type: ${event}`);
    }
  };

  // Handle browser visibility change (e.g., laptop wakes up)
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      if (!isConnected.value) {
        console.log(
          "Document is visible, reconnecting WebSocket connection..."
        );
        const { token } = store.state;
        connectWebSocket(
          `${process.env.VUE_APP_SOCKET_URL || "ws://localhost:3000"}`,
          token
        );
      }
    }
  };

  // Handle network reconnection
  const handleOnlineStatus = () => {
    console.log("Network status changed, online:", navigator.onLine);
    if (navigator.onLine && !isConnected.value) {
      const { token } = store.state;
      connectWebSocket(
        `${process.env.VUE_APP_SOCKET_URL || "ws://localhost:3000"}`,
        token
      );
    }
  };

  // Automatically handle WebSocket connection based on authentication state
  watch(
    token,
    () => {
      if (isAuthenticated.value) {
        if (
          !websocket.value ||
          (websocket.value.readyState !== WebSocket.OPEN &&
            websocket.value.readyState !== WebSocket.CONNECTING)
        ) {
          // Connect to the WebSocket with authentication token
          connectWebSocket(
            `${process.env.VUE_APP_SOCKET_URL || "ws://localhost:3000"}`,
            token.value
          );
        }
      } else {
        if (websocket.value) {
          websocket.value.close();
          websocket.value = null;
        }
      }
    },
    { immediate: true, deep: true }
  );

  // Listen for browser visibility changes and network changes
  onMounted(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);
  });

  // Clean up event listeners on unmount
  onUnmounted(() => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
    window.removeEventListener("online", handleOnlineStatus);
    window.removeEventListener("offline", handleOnlineStatus);
  });

  return { isConnected };
}
