import { ref, computed, watch } from "vue";
import { useLabelStore } from "../../../hooks/useLabelStore";

export function useLabels(selectedLabels, onChange) {
  const { labels, createLabel, createNoteLabel, deleteNoteLabel } =
    useLabelStore();
  const searchTerm = ref("");
  const showLabelMenu = ref(false);
  const filteredLabels = ref([]);
  const savingLabels = ref({});

  // const preSave = (label) => {};
  const toggleLabelMenu = (e) => {
    if (e) {
      e.labelHandled = true;
    }
    showLabelMenu.value = !showLabelMenu.value;
    filterLabels();
  };

  const filterLabels = () => {
    console.log("filterLabels", searchTerm.value);
    filteredLabels.value = labels.value?.filter((label) =>
      label.name.toLowerCase().includes(searchTerm.value.toLowerCase())
    );
  };
  const isSaving = (label) => {
    console.log("isSaving", label?.id, savingLabels.value);
    return savingLabels.value[label.id] === true;
  };
  const isSelected = (label) => {
    return selectedLabels.value?.map((l) => l.id).includes(label.id);
  };

  const toggleLabel = async (label, noteId, e) => {
    if (e) {
      e.labelHandled = true;
    }
    if (noteId) {
      savingLabels.value[label.id] = true;
    }
    const index = selectedLabels.value.map((l) => l.id).indexOf(label.id);
    const newSelectedLabels = [...selectedLabels.value]; // Create a copy

    if (index >= 0) {
      if (noteId) {
        await deleteNoteLabel(label.id, noteId);
      }
      newSelectedLabels.splice(index, 1); // Remove the label
    } else {
      let newLabel = { name: label.name };
      if (noteId) {
        let createdLabel = await createNoteLabel({ name: label.name, noteId });
        newLabel.id = createdLabel.id;
      }
      newSelectedLabels.push(label); // Add the label
    }
    if (noteId) {
      savingLabels.value[label.id] = false;
    }
    // Emit an event to update the selected labels in the parent component
    onChange(newSelectedLabels);

    filterLabels();
  };

  const canCreateLabel = computed(() => {
    return (
      searchTerm.value.trim().length > 0 &&
      !labels.value.some(
        (label) =>
          label.name.toLowerCase() === searchTerm.value.trim().toLowerCase()
      )
    );
  });

  const createLabelFn = async (labelData, e) => {
    if (e) {
      e.labelHandled = true;
    }
    searchTerm.value = "";
    let newLabel = { name: labelData.name };
    if (labelData.noteId) {
      newLabel = await createLabel(labelData);
    } else {
      newLabel.id = `TMP-${new Date().getTime()}`;
    }
    onChange([
      ...selectedLabels.value,
      { id: newLabel.id, name: newLabel.name },
    ]);
    filterLabels();
    toggleLabelMenu();
  };

  watch(showLabelMenu, () => {
    if (showLabelMenu.value === true) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
  });

  const handleClickOutside = (event) => {
    if (event && event.labelHandled) {
      return;
    }
    if (
      showLabelMenu.value &&
      !document.querySelector(".label-menu")?.contains(event.target)
    ) {
      toggleLabelMenu();
    }
  };

  return {
    searchTerm,
    showLabelMenu,
    filteredLabels,
    filterLabels,
    toggleLabelMenu,
    isSelected,
    // preSave,
    isSaving,
    toggleLabel,
    canCreateLabel,
    createLabel: createLabelFn,
  };
}
