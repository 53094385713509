<template>
  <div class="notifications-panel">
    <h4>Live Alerts</h4>
    <div v-if="checkinAlerts.length > 0" class="notifications-section">
      <ul>
        <li
          class="section"
          v-for="alert in checkinAlertsGrouped"
          :key="alert.location.id"
        >
          <Tag
            severity="secondary"
            :value="getReminderDisplay(alert?.alerts[0])"
          />
          <ul class="reminders">
            <li
              v-for="reminder in alert.alerts"
              :key="reminder.id"
              :style="{
                backgroundColor: reminder.note?.color
                  ? getColor(reminder.note?.color)?.hex
                  : 'white',
              }"
            >
              <button @click="openNote(reminder.note.id)">
                {{ reminder.note.content }}
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div v-if="checkinAlerts.length <= 0" class="notifications-section">
      No alerts at this time
    </div>
    <h4>Invites</h4>
    <div v-if="invites.length > 0" class="notifications-section">
      <ul>
        <li v-for="invite in invites" :key="invite.id">
          <div
            class="invite"
            :style="{
              backgroundColor: invite?.color
                ? getColor(invite?.color)?.hex
                : 'white',
            }"
          >
            <div @click="openPreview(invite.id)">
              {{ invite.content }}
            </div>
            <div class="flex gap-2 mt-2">
              <Button
                class="flex-1"
                label="Decline"
                severity="secondary"
                @click="(e) => reply(e, { id: invite.id, isDismissed: true })"
              />
              <Button
                class="flex-1"
                label="Accept"
                @click="(e) => reply(e, { id: invite.id, isApproved: true })"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="invites.length <= 0" class="notifications-section">
      No pending invites
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import Button from "primevue/button";
import Tag from "primevue/tag";
import { getReminderDisplay } from "../utils/helpers";
import { useDeviceLocation } from "../hooks/useDeviceLocation";
import { useColorAndImage } from "../components/NoteComp/hooks/useColorAndImage";
import { useInvitationStore } from "../hooks/useInvitationStore";

const { colors } = useColorAndImage();
const { invites, fetchInvites, updateInvite } = useInvitationStore();

const emit = defineEmits("close");
const router = useRouter();
const route = useRoute();
const { checkinAlerts } = useDeviceLocation();

const checkinAlertsGrouped = computed(() => {
  const grouped = checkinAlerts.value.reduce((acc, alert) => {
    const locationId = alert.location.id;

    if (!acc[locationId]) {
      acc[locationId] = {
        location: alert.location,
        alerts: [],
      };
    }

    acc[locationId].alerts.push(alert);

    return acc;
  }, {});

  return Object.values(grouped);
});

const getColor = (clr) =>
  colors.value.find((c) => c.name.toLowerCase() === clr.toLowerCase());

const openPreview = (id) => {
  router.push(`/preview/${id}`, { query: route.query });
  emit("close");
};
const openNote = (id) => {
  router.push(`/note/${id}`, { query: route.query });
  emit("close");
};
const reply = async (e, invite) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  await updateInvite(invite.id, invite);
};
onMounted(() => {
  fetchInvites();
});
</script>

<style>
.notifications-panel {
  position: fixed;
  top: 50px; /* Adjust based on your header height */
  right: 0;
  width: 300px;
  height: calc(100vh - 50px);
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 1em;
  text-align: left;
  overflow-y: auto;
}

.notifications-section {
  margin-bottom: 1em;
}

.notifications-section h4 {
  margin-bottom: 0.5em;
}
.notifications-panel .section {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.notifications-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notifications-section li:not(:last-child) {
  padding: 0.5em 0;
  border-bottom: 1px solid #eee;
}
.notifications-section .reminders li {
  padding: 0.5rem 0.25rem;
}
.notifications-section .reminders button {
  background: none;
  border: 0;
  display: block;
  width: 100%;
  text-align: left;
}

.notifications-section .p-tag-label {
  font-size: 0.7rem;
}
.no-notifications {
  text-align: center;
  padding: 1em;
  color: #999;
}
.invite {
  padding: 10px;
  border-radius: 8px;
  text-align: left;
  display: block;
  width: 100%;
  border: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
</style>
