<template>
  <component
    severity="contrast"
    :is="button ? Button : 'div'"
    class="icon"
    v-tooltip.bottom="'Files'"
    rounded
    @click="triggerFileInput"
  >
    <i class="pi pi-image"></i>
    <input
      type="file"
      ref="fileInput"
      @change="handleFileChange"
      multiple
      hidden
    />
  </component>
</template>

<script setup>
import { ref, defineProps, toRefs } from "vue";
import { useUploadStore } from "../../hooks/useUploadStore";
import { useFileStore } from "../../hooks/useFileStore";
import Button from "primevue/button";

const props = defineProps({
  noteId: String,
  uploaded: Function,
  added: Function,
  button: Boolean,
});
const { noteId } = toRefs(props);
const fileInput = ref(null);
const { createNoteFile } = useFileStore();
const { uploadFile } = useUploadStore();

const triggerFileInput = () => {
  fileInput.value.click();
};

const handleFileChange = async (event) => {
  const files = Array.from(event.target.files);
  if (files.length) {
    let newFiles = [];
    const uploadPromises = files.map(async (file) => {
      const newFile = await uploadFile(file, noteId.value);
      if (noteId.value) {
        const res = await createNoteFile([newFile.id], noteId.value);
        newFiles = [...newFiles, ...res];
      } else {
        newFiles = [...newFiles, newFile];
      }
    });

    await Promise.all(uploadPromises);
    if (!noteId.value) {
      props.uploaded(newFiles);
    }
    props.added(newFiles);
  }
  // if (fileInput?.value?.value) {
  //   fileInput.value.value = ""; // Clear the input to allow re-upload of the same file
  // }
};
</script>

<style scoped>
.icon {
  cursor: pointer;
}
</style>
