<template>
  <div
    :class="`icon ${props.disabled ? 'disabled' : ''}`"
    v-tooltip.bottom="'Tasks'"
  >
    <i
      class="pi pi-check-circle"
      @click="
        (e) => {
          e.stopPropagation();
          emit('click');
        }
      "
    ></i>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";

const emit = defineEmits(["click"]);
const props = defineProps({
  disabled: Boolean,
});
</script>

<style scoped>
.icon {
  cursor: pointer;
}
.icon.disabled {
  opacity: 0.5;
}
</style>
