// composables/useContactStore.js
import { computed, ref, onMounted } from "vue";
import { Capacitor } from "@capacitor/core";
import { Contacts } from "@capacitor-community/contacts";
import store from "../store";
import api from "../utils/api";

export function useContactStore() {
  const isLoading = ref(false);
  const contacts = computed(() => store.state.contacts);
  const deviceContactsSupported = ref(
    Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android"
  );

  onMounted(() => {
    fetchContacts();
  });

  async function fetchContacts() {
    try {
      isLoading.value = true;
      const response = await api.get("/api/contacts");
      store.mutations.setState("contacts", response.data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    } finally {
      isLoading.value = false;
    }
  }

  async function createContact(contactData) {
    try {
      const response = await api.post("/api/contacts", contactData);
      store.mutations.addModel("contacts", response.data);
      syncToDevice();
      return response.data;
    } catch (error) {
      console.error("Error creating contact:", error);
      throw error;
    }
  }

  async function updateContact(id, contactData) {
    try {
      const response = await api.put(`/api/contacts/${id}`, contactData);
      store.mutations.updateModel("contacts", id, response.data);
      syncToDevice();
      return response.data;
    } catch (error) {
      console.error("Error updating contact:", error);
      throw error;
    }
  }

  async function deleteContact(id) {
    try {
      await api.delete(`/api/contacts/${id}`);
      store.mutations.removeModel("contacts", id);
      syncToDevice();
    } catch (error) {
      console.error("Error deleting contact:", error);
      throw error;
    }
  }

  async function importFromDeviceContacts() {
    if (deviceContactsSupported.value) {
      try {
        const result = await Contacts.getContacts();
        const importedContacts = result.contacts.map((contact) => ({
          name: contact.displayName || "Unknown",
          phoneNumbers:
            contact.phoneNumbers?.map((p) => ({ number: p.number })) || [],
          emails: contact.emails || [],
          organization: contact.organization,
        }));
        for (const contactData of importedContacts) {
          await createContact(contactData);
        }
        await fetchContacts();
      } catch (error) {
        console.error("Error importing from device contacts:", error);
      }
    } else {
      console.warn("Device contacts are not supported on web");
    }
  }

  async function syncToDevice() {
    if (deviceContactsSupported.value) {
      try {
        const contactList = contacts.value.map((contact) => ({
          displayName: contact.name,
          organization: contact.organization,
          phoneNumbers: contact.phoneNumbers.map((p) => ({ number: p.number })),
          emails: contact.emails,
        }));
        for (const contact of contactList) {
          await Contacts.saveContact(contact);
        }
      } catch (error) {
        console.error("Error syncing contacts to device:", error);
      }
    }
  }

  // New importVCard function to send the .vcf file data to the backend
  async function importVCard(vCardData) {
    try {
      const response = await api.post("/api/contacts/import-vcard", {
        vCardData,
      });
      store.mutations.addModels("contacts", response.data);
      await fetchContacts(); // Refresh contacts after importing
    } catch (error) {
      console.error("Error importing vCard:", error);
    }
  }

  return {
    contacts,
    isLoading,
    fetchContacts,
    createContact,
    updateContact,
    deleteContact,
    importFromDeviceContacts,
    importVCard, // Export the importVCard function for sending vCard data to the backend
    deviceContactsSupported,
  };
}
