<template>
  <Dialog
    v-model:visible="showModal"
    header="Comments"
    :modal="true"
    @update:visible="
      (v) => {
        if (!v) close();
      }
    "
    class="comment-modal"
    :closable="true"
    :draggable="false"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  >
    <div v-if="isLoading" class="text-center">
      <ProgressSpinner />
    </div>

    <template v-else>
      <!-- Comment List -->
      <div class="comments-container">
        <ul class="comments-list" v-if="comments?.length > 0">
          <li v-for="comment in comments" :key="comment.id" class="comment">
            <div class="comment-header">
              <img
                :src="getUserProfilePicture(comment.userId)"
                class="comment-avatar"
              />
              <p class="comment-body flex-1">
                <span class="comment-user-name">
                  {{ getUserName(comment.userId) }}
                </span>
                {{ comment.text }}
              </p>
            </div>

            <!-- Like and Reply Buttons -->
            <div class="comment-actions">
              <small class="text-muted">{{
                formatDate(comment.createdAt)
              }}</small>
              <Button
                icon="pi pi-thumbs-up"
                text
                @click="likeCommentFn(comment.id)"
              />
              <span>{{ comment.likes }}</span>
              <Button
                label="Reply"
                text
                class="text-xs"
                @click="toggleReply(comment.id)"
              />
            </div>
            <!-- Reply Input -->
            <div v-if="showReplyInput[comment.id]">
              <Textarea
                v-model="replyText[comment.id]"
                rows="2"
                placeholder="Add a reply..."
                autoResize
              />
              <Button label="Post Reply" @click="postReply(comment.id)" />
            </div>
          </li>
        </ul>
        <p class="text-center" v-else>
          No comments found. <br />Be the first to leave a comment!
        </p>
      </div>

      <!-- Comment Input / Login State -->
      <div>
        <template v-if="isAuthenticated">
          <div class="sticker-container">
            <Button
              v-for="sticker in stickers"
              :key="sticker"
              icon="pi"
              :label="sticker"
              @click="postSticker(sticker)"
              text
            />
          </div>
          <div class="add-comment">
            <Textarea
              v-model="newComment"
              rows="2"
              placeholder="Add a comment..."
              autoResize
            />
            <Button
              label="Post"
              class="p-mt-2"
              @click="postComment"
              :disabled="!newComment.trim()"
            />
          </div>
        </template>
        <template v-else>
          <p>Please log in to post a comment.</p>
          <Button
            label="Log In"
            icon="pi pi-sign-in"
            class="p-mt-2"
            @click="login"
          />
        </template>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { ref, onMounted, defineProps, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useCommentStore } from "../../hooks/useCommentStore";
import { useUserStore } from "../../hooks/useUserStore";
import { useAuthStore } from "../../hooks/useAuthStore";
import { safeBackRoute } from "../../utils/helpers";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import { useSwipeHandler } from "../../hooks/useSwipe";

const props = defineProps({ noteId: String });
const router = useRouter();
const route = useRoute();

const { fetchUserById, getUserName, getUserProfilePicture } = useUserStore();
const {
  comments,
  isLoading,
  fetchComments,
  createComment,
  likeComment,
  replyToComment,
} = useCommentStore();
const { isAuthenticated } = useAuthStore();

const showModal = ref(true);
const newComment = ref("");
const stickers = ["❤️", "👎", "🔥", "😍", "😂", "😞"];
const replyText = ref({});
const showReplyInput = ref({});

let lastCleared = 0;
const bufferTime = 1000;
const { handleTouchStart, handleTouchEnd } = useSwipeHandler({
  onSwipeUp: () => {
    let top = document.querySelector(`.comments-container`).scrollTop;

    const now = new Date().getTime();

    if (top === 0 && now - lastCleared >= bufferTime) {
      lastCleared = now;
      showModal.value = false;
      close();
    }
  },
});

// Fetch comments on component mount
onMounted(() => {
  fetchComments({ noteId: props.noteId });
});

// Method to post a sticker
const postSticker = async (sticker) => {
  try {
    await createComment({
      text: sticker,
      noteId: props.noteId,
    });
  } catch (error) {
    console.error("Failed to post sticker:", error);
  }
};

// Method to like a comment
const likeCommentFn = async (commentId) => {
  try {
    await likeComment(commentId);
  } catch (error) {
    console.error("Failed to like comment:", error);
  }
};

// Method to reply to a comment
const postReply = async (parentId) => {
  if (!replyText.value[parentId]?.trim()) return;
  try {
    await replyToComment(parentId, {
      text: replyText.value[parentId],
      noteId: props.noteId,
    });
    replyText.value[parentId] = ""; // Clear the reply input
  } catch (error) {
    console.error("Failed to post reply:", error);
  }
};

// Toggle reply input
const toggleReply = (commentId) => {
  showReplyInput.value[commentId] = !showReplyInput.value[commentId];
};

const postComment = async () => {
  if (!newComment.value.trim()) return;
  try {
    await createComment({ text: newComment.value, noteId: props.noteId });
    newComment.value = "";
  } catch (error) {
    console.error("Failed to post comment:", error);
  }
};

const login = () => {
  router.push(`/login?return_url=/notes/${props.noteId}/comments`);
};

watch(comments, () => {
  comments?.value?.forEach((comment) => fetchUserById(comment.userId));
});
const formatDate = (date) => {
  const now = new Date();
  const daysDiff = differenceInDays(now, date);
  const hoursDiff = differenceInHours(now, date);
  const minutesDiff = differenceInMinutes(now, date);

  if (daysDiff > 0) {
    return `${daysDiff}d`;
  } else if (hoursDiff > 0) {
    return `${hoursDiff}h`;
  } else {
    return `${minutesDiff}m`;
  }
};
const close = () => {
  const currentQuery = route.query;
  const path = safeBackRoute();
  router.push({ path, query: currentQuery });
};
</script>

<style>
.comment-actions {
  margin-left: 46px;
}
.comment-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  float: left;
}
.comment-user-name {
  font-weight: 600;
  margin-right: 4px;
}
.comment-modal {
  width: 600px;
  max-width: 100%;
  height: 80vh;
  border-radius: 10px !important;
}
.comment-modal .p-dialog-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.comment-user-name {
  margin: 0;
}
.comments-container {
  flex: 1;
  overflow-y: auto;
}

.comments-list {
  list-style-type: none;
  padding: 0;
}

.comment {
  margin-bottom: 1.5rem;
}
.sticker-container {
  width: 100%;
  display: flex;
}
.sticker-container button {
  flex: 1;
}
.comment-body {
  margin: 0.5rem 0;
  padding-left: 46px;
  font-size: 14px;
  text-align: left;
}

.add-comment {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
}
.add-comment button {
  height: 3.25rem;
}
.add-comment textarea {
  font-size: 16px;
  flex: 1;
}
</style>
