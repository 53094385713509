<template>
  <div class="icon" @click="toggleColorPaletteMenu">
    <i class="pi pi-palette" v-tooltip.bottom="'Color'"></i>
  </div>
</template>

<script setup></script>

<style scoped>
.icon {
  position: relative;
}

.color-palette-menu {
  position: absolute;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px;
  z-index: 1000;
}
</style>
