<template>
  <div class="profile-page flex-1 flex flex-column align-items-center p-4">
    <div
      class="surface-card shadow-2 border-round p-4 mb-4 w-full md:w-8 lg:w-6"
    >
      <div class="flex justify-content-between align-items-center mb-4">
        <div class="flex align-items-center">
          <div
            :style="{
              backgroundImage:
                user.profilePicture ||
                `url('https://placehold.co/600x400/EEE/31343C?font=open-sans&text=${user?.email?.charAt(
                  0
                )}')`,
            }"
            alt="User Profile"
            class="profile-pic mr-3"
          />

          <div class="text-left">
            <h2 class="text-2xl font-bold">{{ user.username }}</h2>
            <p class="text-secondary">{{ user.email }}</p>
          </div>
        </div>
        <Button
          label="Edit Profile"
          icon="pi pi-pencil"
          class="p-button-sm p-button-outlined"
          @click="$router.push('/profile/edit')"
        />
      </div>

      <div class="mt-4">
        <h3 class="text-lg font-medium">About Me</h3>
        <p v-if="user.bio" class="mt-2">{{ user.bio }}</p>
        <p v-else class="mt-2 text-secondary">Add a bio to your profile...</p>
      </div>

      <div class="mt-4">
        <h3 class="text-lg font-medium">Interests & Achievements</h3>
        <ul class="list-none p-0 mt-2">
          <li v-for="interest in user.interests" :key="interest" class="mb-1">
            {{ interest }}
          </li>
          <li
            v-for="achievement in user.achievements"
            :key="achievement"
            class="mb-1"
          >
            {{ achievement }}
          </li>
        </ul>
        <p
          v-if="!user.interests?.length && !user.achievements?.length"
          class="text-secondary"
        >
          Add some interests and achievements to your profile...
        </p>
      </div>

      <Button
        label="Change Password"
        icon="pi pi-key"
        class="p-button-sm mt-4"
        @click="changePassword"
      />
    </div>

    <Dialog
      header="Edit Profile"
      v-model:visible="showEditProfile"
      :modal="true"
      :closable="false"
      :draggable="false"
      class="w-full md:w-6"
    >
      <form @submit.prevent="saveProfile" class="p-fluid">
        <div class="field">
          <label for="profilePicture">Profile Picture</label>
          <FileUpload
            id="profilePicture"
            name="profilePicture"
            customUpload
            mode="basic"
            accept="image/*"
            :auto="true"
            @select="handleProfilePictureUpload"
            chooseLabel="Change Profile Picture"
            class="w-full"
          />
          <img
            v-if="editData.profilePicture"
            :src="editData.profilePicture"
            alt="Profile Picture Preview"
            class="profile-pic-preview mt-3"
          />
        </div>
        <div class="field">
          <label for="bio">Bio</label>
          <InputTextarea
            id="bio"
            v-model="editData.bio"
            rows="3"
            placeholder="Tell us about yourself"
            class="w-full"
          />
        </div>
        <div class="field">
          <label for="interests">Interests</label>
          <Chips
            id="interests"
            v-model="editData.interests"
            placeholder="Add Interests"
            separator=","
            class="w-full"
            @blur="handleChipsBlur($event, editData, 'interests')"
          />
        </div>
        <div class="field">
          <label for="achievements">Achievements</label>
          <Chips
            id="achievements"
            v-model="editData.achievements"
            placeholder="Add Achievements"
            separator=","
            class="w-full"
            @blur="handleChipsBlur($event, editData, 'achievements')"
          />
        </div>
        <div class="flex justify-content-end mt-3">
          <Button
            label="Save"
            icon="pi pi-check"
            type="submit"
            class="mr-2 p-button-success"
          />
          <Button
            label="Cancel"
            icon="pi pi-times"
            class="p-button-secondary"
            @click="$router.push('/profile')"
          />
        </div>
      </form>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed, watch, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
// import InputText from "primevue/inputtext";
import InputTextarea from "primevue/textarea";
import Chips from "primevue/chips";
import FileUpload from "primevue/fileupload";
import store from "../store";
import api from "../utils/api.js";
import { handleChipsBlur } from "../utils/helpers";
import { useSidebarState } from "../hooks/useSidebarState";

const { isSidebarOpen, toggleSidebar } = useSidebarState();

const props = defineProps({
  userId: String,
});
const authedUserId = computed(() => store?.state?.user?.id);
const user = ref({});
const editData = ref({
  profilePicture: "",
  bio: "",
  interests: [],
  achievements: [],
});
const showEditProfile = ref(false);
const router = useRouter();
const route = useRoute();

const fetchUserProfile = async () => {
  try {
    const response = await api.get(
      `/api/users/${props.userId || authedUserId.value}`
    );
    user.value = response.data;
    editData.value.profilePicture = user.value.profilePicture;
  } catch (error) {
    console.error("Error fetching user profile:", error);
  }
};

const editProfile = () => {
  editData.value = { ...user.value };
  showEditProfile.value = true;
};
watch(
  route,
  () => {
    if (route.meta.edit === true) {
      editProfile();
    } else {
      editData.value = null;
      showEditProfile.value = false;
    }
  },
  { immediate: true, deep: true }
);

const saveProfile = async () => {
  try {
    const response = await api.put(
      `/api/users/${user.value.id}`,
      editData.value
    );
    user.value = response.data;
    showEditProfile.value = false;
  } catch (error) {
    console.error("Error updating user profile:", error);
  }
};

const handleProfilePictureUpload = async (event) => {
  const file = event.files[0];
  console.log("e", event, file);
  if (file) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await api.post(
        "/api/users/upload-profile-picture",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      editData.value.profilePicture = response.data.url;
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  }
};

const changePassword = () => {
  router.push("/change-password");
};

onMounted(fetchUserProfile);

let wasOpened = false;
onMounted(() => {
  wasOpened = isSidebarOpen.value;
  if (wasOpened) {
    toggleSidebar();
  }
});
onUnmounted(() => {
  if (wasOpened && !isSidebarOpen.value) {
    toggleSidebar();
  }
});
</script>

<style scoped>
.profile-page {
  min-height: calc(var(--vh, 1vh) * 100);
}

.profile-pic {
  width: 100px;
  height: 100px;
  border: 1px solid #fff;
  text-align: center;
  border-radius: 50%;
}

.profile-pic-preview {
  width: 100px;
  height: 100px;
  border: 1px solid #fff;
  text-align: center;
  border-radius: 50%;
}

.text-secondary {
  color: #6c757d;
}
</style>
