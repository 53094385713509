import { computed, ref } from "vue";
import store from "../store";
import api from "../utils/api";

export function useEventStore() {
  const isLoading = ref(false);
  const events = computed(() => store.state.events);

  async function fetchEvents(query) {
    try {
      isLoading.value = true;
      const response = await api.get("/api/events", { params: query });
      store.mutations.setState("events", response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      isLoading.value = false;
    }
  }

  async function createEvent(eventData) {
    try {
      const response = await api.post("/api/events", eventData);
      store.mutations.addModel("events", response.data);
      return response.data;
    } catch (error) {
      console.error("Error creating event:", error);
      throw error;
    }
  }

  async function updateEvent(id, eventData) {
    try {
      const response = await api.put(`/api/events/${id}`, eventData);
      store.mutations.updateModel("events", id, response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating event:", error);
      throw error;
    }
  }

  async function deleteEvent(id) {
    try {
      await api.delete(`/api/events/${id}`);
      store.mutations.removeModel("events", id);
    } catch (error) {
      console.error("Error deleting event:", error);
      throw error;
    }
  }

  async function fetchEventById(eventId) {
    try {
      const response = await api.get(`/api/events/${eventId}`);
      store.mutations.addModel("events", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching event by id:", error);
      throw error;
    }
  }

  async function searchEvents(query) {
    try {
      const response = await api.get("/api/events", { params: query });
      return response.data;
    } catch (error) {
      console.error("Error searching events:", error);
      throw error;
    }
  }

  const calculateDateRange = (baseDate) => {
    const startOfMonth = new Date(
      baseDate.getFullYear(),
      baseDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      baseDate.getFullYear(),
      baseDate.getMonth() + 1,
      0
    );

    const startDate = new Date(startOfMonth);
    startDate.setMonth(startOfMonth.getMonth() - 1);

    const endDate = new Date(endOfMonth);
    endDate.setMonth(endOfMonth.getMonth() + 1);

    return { startDate, endDate };
  };

  return {
    events,
    isLoading,
    fetchEvents,
    createEvent,
    updateEvent,
    deleteEvent,
    fetchEventById,
    searchEvents,
    calculateDateRange,
  };
}
