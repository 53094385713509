<template>
  <component
    :id="note ? `${isInVisible ? 'hidden-' : ''}note-${note.id}` : 'note-new'"
    :is="preview || (!note && expanded) ? Dialog : 'div'"
    :visible="true"
    :class="[
      'note',
      {
        expanded,
        invisible: isInVisible,
        hasModel: !!note,
        hover: setHover,
      },
      `${viewMode}-view`,
    ]"
    :style="noteStyle"
    :draggable="true"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
    modal
  >
    <!-- Expanded mode for new or existing note -->
    <div class="expanded-wrap" v-if="expanded">
      <div
        class="flex align-items-center sticky top-0 z-1 gap-2 p-dialog-header"
        :style="footerStyle"
      >
        <Button class="" text icon="pi pi-times" @click="closeNote" />
        <!-- <div class="flex-1 text-center pl-8" v-if="!note?.id">New Note</div> -->
        <div class="flex-1" />
        <ColorPaletteMenu
          :colors="colors"
          :images="images"
          :selectColor="selectColor"
          :selectImage="selectImage"
          :selectedColor="selectedColor"
          :selectedImage="selectedImage"
          v-if="canEdit"
        />
        <Button
          text
          label="Create"
          class="post-button"
          @click="saveNote"
          v-if="!note?.id"
          :icon="isSaving ? 'pi pi-spinner spin' : null"
          :disabled="isSaving"
        />
        <PinIcon
          :class="{
            pin: true,
            pinned: pinned,
          }"
          @click="
            () => {
              if (note?.id) {
                updateNote(note?.id, { ...note, isPinned: !pinned });
              }
              pinned = !pinned;
            }
          "
          v-if="isApproved"
          v-tooltip.bottom="`${pinned ? 'Unpin' : 'Pin'}`"
        />
      </div>
      <header>
        <textarea
          ref="noteContent"
          v-model="content"
          placeholder="Take a note..."
          class="note-content"
          :disabled="!canEdit"
          @input="adjustTextareaHeight"
          @focus="() => (lastVal = content)"
          @blur="
            () => {
              if (content !== lastVal) {
                if (note?.id) {
                  debouncedSaveNote();
                }
              }
              lastVal = null;
            }
          "
        />
      </header>
      <div class="body flex-1 flex flex-column">
        <FileGrid :files="files" :noteId="note?.id" />
        <TaskList
          v-if="showTasks || tasks?.length > 1"
          :tasks="tasks"
          :readOnly="!canEdit"
          :expanded="expanded"
          :noteId="note?.id"
          @update="
            (val) => {
              tasks = val;
            }
          "
        />
        <div class="flex-1" />
        <div class="labels flex gap-1">
          <component
            :is="false ? 'span' : RouterLink"
            :to="`/?labels=${label.name}`"
            :key="label"
            v-for="label in selectedLabels"
          >
            <Tag severity="secondary" :value="label.name" />
          </component>
        </div>
        <div class="note-reminders-preview" v-if="reminders?.length > 0">
          <span :key="reminder" v-for="reminder in reminders">
            <Tag severity="secondary" :value="getReminderDisplay(reminder)" />
          </span>
        </div>
      </div>
      <footer :style="footerStyle">
        <div class="note-actions" v-if="canEdit">
          <ReminderIcon
            :noteId="note?.id"
            :id="reminders[0]?.id"
            @opened="setHover = true"
            @closed="setHover = false"
            @change="(val) => (reminders = val)"
            :selectedDate="computedSelectedDate"
            :selectedTime="computedSelectedTime"
            :frequency="reminders[0]?.frequency"
            :interval="reminders[0]?.interval"
            :endCondition="computedEndCondition"
            :endAfterOccurrences="reminders[0]?.count"
            :endOnDate="reminders[0]?.endOnDate"
            :locationTriggerType="reminders[0]?.locationTriggerType || 'arrive'"
            :selectedLocation="
              reminders[0]?.locationId ? reminders[0].location : null
            "
          />
          <CollaboratorIcon
            :noteId="note?.id"
            :shareUrl="note?.shareUrl"
            @opened="setHover = true"
            @closed="setHover = false"
            @change="(val) => (collaborators = val)"
          />
          <ImageIcon
            :noteId="note?.id"
            :uploaded="
              (newUploads) => {
                files = [...files, ...newUploads];
              }
            "
            @click="expandNote($event, true)"
          />
          <ChecklistIcon :disabled="tasks.length > 2" @click="toggleTasks" />
          <LabelMenu
            :selectedLabels="selectedLabels"
            :noteId="note?.id"
            @update:selectedLabels="(val) => (selectedLabels = val)"
          />
          <CommentIcon :noteId="note?.id" />
          <MoreOptionsIcon @toggleTasks="toggleTasks" :noteId="note?.id" />
        </div>
        <!-- <Button
          text
          severity="secondary"
          label="Discard"
          class="close-button"
          @click="closeNote"
        />
        <Button
          label="Save"
          class="post-button"
          @click="saveNote"
          v-if="!note?.id"
        /> -->
      </footer>
    </div>

    <!-- Collapsed mode for viewing an existing note -->
    <div
      v-else-if="note"
      tabindex="0"
      :class="[
        'collapsed-note',
        { 'list-view': viewMode === 'list', 'grid-view': !viewMode === 'list' },
      ]"
    >
      <div class="note-preview flex-1 relative" @click="expandNote">
        <div class="flex align-items-start flex-grow-1 gap-2">
          <div class="note-content-preview" @click="expandNote">
            <span class="icon-container">
              <ColorPaletteMenu
                :colors="colors"
                :images="images"
                :selectColor="selectColor"
                :selectImage="selectImage"
                :selectedColor="selectedColor"
                :selectedImage="selectedImage"
                v-if="canEdit"
                class="color-icon"
                @click="
                  (e) => {
                    e.stopPropagation();
                  }
                "
              />
              <PinIcon
                :class="{
                  pin: true,
                  pinned: pinned,
                }"
                @click="
                  (e) => {
                    e.stopPropagation();
                    if (note?.id) {
                      updateNote(note?.id, { ...note, isPinned: !pinned });
                    }
                    pinned = !pinned;
                  }
                "
                v-tooltip.bottom="`${note?.isPinned ? 'Unpin' : 'Pin'}`"
              />
            </span>
            {{ note.content }}
          </div>
        </div>
        <FileGrid :files="files" :noteId="note?.id" />
        <div class="relative">
          <TaskList
            v-if="showTasks || tasks?.length > 1"
            :readOnly="!canEdit"
            :expanded="expanded"
            :tasks="tasks"
            :preview="preview"
            :noteId="note?.id"
            @update="
              (val) => {
                tasks = val;
              }
            "
          />
          <div class="task-mask" @click="expandNote" />
        </div>
        <div class="note-labels-preview">
          <component
            :is="false ? 'span' : RouterLink"
            :to="`/?labels=${label.name}`"
            :key="label"
            v-for="label in selectedLabels"
            @click="(e) => e.stopPropagation()"
          >
            <Tag severity="secondary" :value="label.name" />
          </component>
        </div>
        <div class="note-reminders-preview" v-if="reminders?.length > 0">
          <span :key="reminder" v-for="reminder in reminders">
            <Tag severity="secondary" :value="getReminderDisplay(reminder)" />
          </span>
        </div>
      </div>
    </div>

    <!-- Collapsed mode for a new empty note -->
    <template v-else>
      <ButtonGroup class="new-actions">
        <Button
          icon="pi pi-filter"
          rounded
          @click="setFiltersOpen(true)"
          severity="contrast"
          v-tooltip.top="'Filters'"
        />
        <Button
          icon="pi pi-plus"
          class="new-btn"
          rounded
          @click="expandNote"
          severity="success"
          v-tooltip.top="'New Note'"
        />

        <ImageIcon
          button
          :noteId="note?.id"
          :uploaded="
            (newUploads) => {
              console.log('newUpload collapsed', newUploads);
              files = [...files, ...newUploads];
            }
          "
          @click="expandNote($event, true)"
        />
      </ButtonGroup>
      <div tabindex="0" :class="['collapsed-note']">
        <textarea
          v-model="content"
          placeholder="Take a note..."
          class="note-content"
          readonly
          @click="expandNote"
          @focus="expandNote"
        ></textarea>
        <div class="collapsed-icons">
          <ChecklistIcon @click="toggleTasks" />
          <ImageIcon
            :noteId="note?.id"
            :uploaded="
              (newUploads) => {
                console.log('newUpload collapsed', newUploads);
                files = [...files, ...newUploads];
              }
            "
            @click="expandNote($event, true)"
          />
        </div>
      </div>
    </template>

    <div class="menu-expand" />
  </component>
</template>

<script setup>
import { ref, nextTick, defineProps, toRefs, watch, computed } from "vue";
import { RouterLink, useRouter, useRoute } from "vue-router";
import Tag from "primevue/tag";
import { useFilters } from "../../hooks/useFilters";
import PinIcon from "./PinIcon.vue";
import ReminderIcon from "./ReminderIcon.vue";
import CommentIcon from "./CommentIcon.vue";
import CollaboratorIcon from "./CollaboratorIcon.vue";
import ImageIcon from "./ImageIcon.vue";
import MoreOptionsIcon from "./MoreOptionsIcon.vue";
import ChecklistIcon from "./ChecklistIcon.vue";
import LabelMenu from "./LabelMenu.vue";
import Button from "primevue/button";
import ButtonGroup from "primevue/buttongroup";
import Dialog from "primevue/dialog";
import TaskList from "./TaskList.vue";
import ColorPaletteMenu from "./ColorPaletteMenu.vue";
import FileGrid from "./FileGrid.vue";

import {
  isMobile,
  debounce,
  getReminderDisplay,
  safeBackRoute,
} from "../../utils/helpers";
import { useAuthStore } from "../../hooks/useAuthStore.js";
import { useColorAndImage } from "./hooks/useColorAndImage.js";
import { useNoteStore } from "../../hooks/useNoteStore"; // Assuming this is the correct import path
import { useSwipeHandler } from "../../hooks/useSwipe";

const { setFiltersOpen } = useFilters();
const { user } = useAuthStore();
const props = defineProps({
  note: Object,
  preview: Boolean,
  viewMode: {
    type: String,
    default: "grid", // Default to grid view
  },
});

const showAddBtn = ref(false);
watch(
  () => window.innerWidth,
  () => {
    showAddBtn.value = window.innerWidth <= 640;
  },
  { immediate: true }
);
const { note } = toRefs(props);

const {
  colors,
  images,
  selectColor,
  selectImage,
  noteStyle,
  footerStyle,
  selectedColor,
  selectedImage,
} = useColorAndImage(props?.note);

const { createNote, updateNote } = useNoteStore(); // Access note service methods

const TMPTASK = { id: "TMP_NEW", description: "", isTmp: true, order: 0 };

const lastVal = ref("");

const router = useRouter();
const route = useRoute();
// State for the note
const expanded = ref(props.preview);

const canEdit = ref(true);
const isApproved = ref(false);
const isDismissed = ref(false);

const isSaving = ref(false);
const setHover = ref(false);
const showTasks = ref(false);
const pinned = ref(false);
const tasks = ref([TMPTASK, TMPTASK]);
const reminders = ref([]);
const collaborators = ref([]);
const title = ref("");
const content = ref("");
const noteContent = ref(null);
const selectedLabels = ref([]);
const files = ref([]);

const isInVisible = computed(
  () =>
    note?.value?.id && !props.preview && route.params?.id === note?.value?.id
);

watch(isInVisible, () => {
  if (!isInVisible.value) {
    nextTick(() => {
      if (note.value) {
        initFromProps();
      }
    });
  }
});
let lastCleared = 0;
const bufferTime = 2000; // Adjust the buffer time in milliseconds

const { handleTouchStart, handleTouchEnd } = useSwipeHandler({
  onSwipeUp: () => {
    if (expanded.value) {
      let top = document.querySelector(
        `${
          note?.value?.id ? `#note-${note?.value?.id}` : "#note-new"
        } .expanded-wrap`
      ).scrollTop;

      const now = new Date().getTime();

      if (top === 0 && now - lastCleared >= bufferTime) {
        lastCleared = now;
        closeNote();
      }
    }
  },
});

const initFromProps = () => {
  pinned.value = note?.value?.isPinned ?? false;
  const thisUser = note?.value?.collaborators?.find(
    (c) => c.id === user.value?.id
  );
  canEdit.value =
    note?.value?.userId === user.value?.id ||
    (thisUser?.Collaborator.role === "edit" &&
      thisUser?.Collaborator.isApproved === true);
  isApproved.value =
    note?.value?.userId === user.value?.id ||
    thisUser?.Collaborator.isApproved === true;
  isDismissed.value = thisUser?.Collaborator.isDismissed === true;

  tasks.value = note?.value.tasks
    ? [
        ...note?.value.tasks,
        {
          id: "TMP_NEW",
          description: "",
          isTmp: true,
          order: 0,
          noteId: props.note?.id,
        },
      ]
    : [{ id: "TMP_NEW", description: "", isTmp: true, order: 0 }];
  if (note?.value.tasks?.length == 0) {
    nextTick(() => {
      if (!isMobile) {
        document
          .querySelector(
            `${
              note?.value?.id ? `#note-${note?.value?.id}` : "#note-new"
            } .note-content`
          )
          ?.focus();
      }
    });
  }
  if (note?.value?.color) {
    selectColor(
      colors.value.find(
        (c) => c.name.toLowerCase() === note?.value?.color?.toLowerCase()
      )
    );
  }
  files.value = note?.value.files;
  reminders.value = note?.value.reminders || [];

  // noteContent.value = null;
  content.value = note?.value.content || "";

  collaborators.value = note?.value ? note?.value.collaborators : [];
  selectedLabels.value = note?.value?.labels || [];
};
if (note?.value) {
  initFromProps();
}
// UI logic
const expandNote = (e, selectFiles) => {
  if (e) {
    e.stopPropagation();
    // e.preventDefault();
    e.target.blur();
  }
  if (note?.value?.id) {
    const currentQuery = route.query;
    router.push({ path: `/note/${note?.value?.id}`, query: currentQuery });
    return;
  }
  expanded.value = true;
  nextTick(() => {
    if (!showTasks.value) {
      document
        .querySelector(
          `${
            note?.value?.id ? `#note-${note?.value?.id}` : "#note-new"
          } .note-content`
        )
        ?.focus();

      adjustTextareaHeight();
    }
    if (selectFiles) {
      // document
      //   .querySelector(
      //     `${
      //       note?.value?.id ? `#note-${note?.value?.id}` : "#note-new"
      //     } input[type=file]`
      //   )
      //   ?.click();
    }
  });
};

const closeNote = (e) => {
  if (e) {
    e.stopPropagation();
  }
  if (props.preview) {
    const currentQuery = route.query;
    const path = safeBackRoute();
    router.push({ path, query: currentQuery });
    return;
  }
  expanded.value = false;
  pinned.value = false;
  // showTasks.value = tasks.value.length === 1 ? false : true;
  if (!note.value) {
    // Reset fields for new notes
    title.value = "";
    content.value = "";
    selectColor(null);
    selectImage(null);
    showTasks.value = false;
    selectedLabels.value = [];
    tasks.value = [TMPTASK, TMPTASK];
    files.value = [];
    collaborators.value = [];
    reminders.value = [];
    isApproved.value = false;
    isDismissed.value = false;
    canEdit.value = true;
  } else {
    initFromProps();
  }
};

const adjustTextareaHeight = () => {
  nextTick(() => {
    if (noteContent.value) {
      noteContent.value.style.height = "auto";
      noteContent.value.style.height = `${noteContent.value?.scrollHeight}px`;
    }
  });
};

const toggleTasks = () => {
  console.log("toggle", tasks.value.length === 1);
  if (tasks.value?.length <= 2) {
    showTasks.value = !showTasks.value;
    console.log("showTasks toggleTasks", showTasks.value);
  }

  if (!expanded.value) {
    expandNote();
  }
};

// Save function to post or update note
const saveNote = async () => {
  isSaving.value = true;
  console.log("saveNote", selectedLabels.value, tasks.value);
  const noteModel = {
    title: title.value,
    content: content.value || null,
    color: selectedColor.value?.name?.toLowerCase() || null,
    isPinned: props.note ? props.note.isPinned : pinned.value,
    collaborators:
      collaborators.value?.map((c) => ({ role: c.role, email: c.email })) || [],
    reminders: props.note
      ? props.note.reminders
      : reminders.value.map((r) => ({ ...r.event, ...r.location })) || [],
    labels: selectedLabels.value,
    tasks:
      tasks.value
        ?.filter((task) => task.description !== "")
        .map((t) => {
          const ret = { ...t };
          if (ret.id.indexOf("TMP_NEW") > -1) {
            delete ret.id;
          }
          return ret;
        }) || [], // Only save tasks with text
    files: files.value?.map((f) => f.id) || [],
  };

  try {
    if (props.note?.id) {
      // Update existing note
      await updateNote(props.note?.id, noteModel);
    } else {
      // Create a new note
      await createNote(noteModel);
    }
    isSaving.value = false;
    closeNote(); // Optionally close the note after saving
  } catch (error) {
    console.error("Error saving note:", error);
  }
};

const debounceCb = async () => {
  console.log("debounce save", selectedColor.value);
  updateNote(note?.value?.id, {
    ...note.value,
    title: title.value,
    content: content.value,
    color: selectedColor.value?.name?.toLowerCase(),
    backgroundImage: selectedImage.value,
  });
};

// Debounced save function
const debouncedSaveNote = debounce(() => {
  debounceCb();
}, 1000);

watch([selectedColor, selectedImage], () => {
  if (
    title.value === note?.value?.title &&
    content.value === note?.value?.content &&
    selectedColor.value?.name?.toLowerCase() ===
      note?.value?.color?.toLowerCase()
  ) {
    return;
  }
  if (note?.value?.id) {
    debouncedSaveNote();
  }
});

watch(noteContent, () => {
  if (noteContent.value) {
    adjustTextareaHeight();
  }
});

watch(
  () => note.value?.id,
  () => {
    if (props.preview) {
      nextTick(() => {
        if (!showTasks.value) {
          noteContent.value.focus();
          adjustTextareaHeight();
        }
      });
    }
  }
);
watch(
  note,
  () => {
    initFromProps();
    nextTick(() => {
      adjustTextareaHeight();
    });
  },
  { deep: true }
);
</script>

<style scoped>
.new-actions {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}
.new-actions button {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  color: #fff !important;
  height: 3rem !important;
  width: 3rem !important;
}
.new-actions .new-btn {
  color: #fff !important;
}
/* .new-btn {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  color: #fff !important;
  height: 3rem !important;
  width: 3rem !important;
} */
</style>
