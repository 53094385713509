<template>
  <div>
    <FloatLabel>
      <DatePicker
        ref="picker"
        :modelValue="localDueDate"
        @update:modelValue="(val) => (localDueDate = val)"
        @hide="hide"
        @show="isSaving = false"
        class="w-full"
        placeholder=""
        showButtonBar
        showTime
        hourFormat="12"
        id="taskDueDate"
      >
        <template #header>
          <div class="flex-none w-full flex">
            <Button @click="closeDatePicker" icon="pi pi-times" text />
            <div class="flex-1" />
            <Button @click="applyDueDate($event)" label="Save" text />
          </div>
          <!-- <Button @click="clearDate" label="Clear" class="ml-2" /> -->
        </template>
      </DatePicker>
      <label for="on_label">Due Date</label>
    </FloatLabel>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import DatePicker from "primevue/datepicker";
import Button from "primevue/button";
import FloatLabel from "primevue/floatlabel";

const emit = defineEmits(["onDueDateChange"]);
const props = defineProps({
  dueDate: String,
});
const isSaving = ref(false);
const picker = ref();
const localDueDate = ref(props.dueDate ? new Date(props.dueDate) : null);

const hide = () => {
  if (!isSaving.value) {
    localDueDate.value = props.dueDate ? new Date(props.dueDate) : null;
  }
};
// Emit the ISO 8601 formatted date string
const applyDueDate = (e) => {
  if (e) {
    e.preventDefault();
  }
  isSaving.value = true;
  if (localDueDate.value) {
    const isoDateString = new Date(localDueDate.value).toISOString();
    emit("onDueDateChange", isoDateString);
  }
  closeDatePicker();
  //   nextTick(() => {
  //     isSaving.value = false;
  //   });
};

// Clear the date and emit null
// const clearDate = () => {
//   localDueDate.value = null;
//   emit("onDueDateChange", null);
//   closeDatePicker();
// };

// Method to programmatically close the DatePicker overlay
const closeDatePicker = () => {
  picker.value.overlayVisible = false;
};
</script>

<style scoped>
.p-datepicker-header {
  flex-wrap: wrap;
}
</style>
