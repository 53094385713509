<template>
  <div class="pb-4">
    <h2 class="text-center">
      <img
        class="profile-pic-lg block mx-auto mb-2"
        :src="
          localContact?.photo
            ? `data:image/jpeg;base64,${localContact?.photo}`
            : `https://placehold.co/400x400/EEE/31343C?font=open-sans&text=${
                localContact?.name?.charAt(0) || 'U'
              }`
        "
        aria-label="User Profile"
      />
      {{ localContact?.name }}
      <div>
        <div>
          <small>{{ localContact?.title }}</small>
        </div>
        <small>{{ localContact?.organization }}</small>
      </div>
    </h2>

    <p class="font-bold" v-if="localContact?.emails?.length > 0">Emails</p>
    <ul class="m-0 p-0 list-none">
      <li
        v-for="(email, index) in localContact?.emails"
        :key="index"
        class="mb-1"
      >
        <template v-if="Array.isArray(email.type)">
          <Chip
            class="label mr-2"
            v-for="(type, typeIndex) in email.type"
            :key="typeIndex"
            :label="type"
          />
        </template>
        <template v-else>
          <Chip class="label mr-2" :label="email.type" />
        </template>
        {{ email.email }}
      </li>
    </ul>

    <p class="font-bold" v-if="localContact?.phoneNumbers?.length > 0">
      Phone Numbers
    </p>
    <ul class="m-0 p-0 list-none">
      <li
        v-for="(phone, index) in localContact?.phoneNumbers"
        :key="index"
        class="mb-1"
      >
        <template v-if="Array.isArray(phone.type)">
          <Chip
            class="label mr-2"
            v-for="(type, typeIndex) in phone.type"
            :key="typeIndex"
            :label="type"
          />
        </template>
        <template v-else>
          <Chip class="label mr-2" :label="phone.type" />
        </template>
        {{ phone.number }}
      </li>
    </ul>
    <p class="font-bold" v-if="localContact?.addresses?.length > 0">
      Addresses
    </p>
    <ul class="m-0 p-0 list-none">
      <li
        v-for="(address, index) in localContact?.addresses"
        :key="index"
        class="mb-1"
      >
        <template v-if="Array.isArray(address.type)">
          <Chip
            class="label mr-2"
            v-for="(type, typeIndex) in address.type"
            :key="typeIndex"
            :label="type"
          />
        </template>
        <template v-else>
          <Chip class="label mr-2" :label="address.type" />
        </template>
        {{
          Array.isArray(address.address)
            ? address.address.join(" ")
            : address.address
        }}
      </li>
    </ul>
    <p class="font-bold" v-if="localContact?.notes?.length > 0">Notes</p>
    <p>{{ localContact?.notes }}</p>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import Chip from "primevue/chip";
import { useContactStore } from "../hooks/useContactStore";

const { contacts } = useContactStore();

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  contact: Object,
});

const localContact = computed(() =>
  contacts.value?.find((c) => c.id === props.contact?.id)
);
</script>
<style>
.label {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.8rem;
  background-color: var(--corp-slate-500) !important;
  color: #fff !important;
}
</style>
