import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import store from "../store";
import api from "../utils/api";

import { usePreferenceStore } from "./usePrefrenceStore";

let refreshTimeout = null;
const hasMounted = ref(false);

export function useAuthStore() {
  const isPrimary = ref(false);
  const user = computed(() => store.state.user);
  const authCode = computed(() => store.state.authCode);
  const isAuthenticating = computed(
    () => !!store.state.isAuthenticating && !store.state.prefrences
  );
  const isAuthenticated = computed(() => store.state.token);
  const token = computed(() => store.state.token);
  const refreshToken = computed(() => store.state.refreshToken);

  function parseJwt(token) {
    if (!token) return null;
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
  async function generateAuthCode() {
    console.log("generateAuthCode");
    try {
      const response = await api.post("/auth/generate-auth-code");
      store.mutations.setState("authCode", response.data.code); // Store the code
      return response.data.code;
    } catch (error) {
      console.error("Error generating authorization code:", error);
      throw error;
    }
  }

  async function validateTokenRefresh(token, refreshToken, user) {
    console.log("validateTokenRefresh");
    if (!token) return;

    const decodedToken = parseJwt(token);
    if (!decodedToken) return;

    const expiresAt = decodedToken.exp * 1000;
    const refreshAt = expiresAt - 60 * 1000; // Refresh 1 minute before expiration
    const delay = refreshAt - Date.now();

    if (delay <= 0) {
      const response = await api.post("/auth/refresh-token", {
        token: refreshToken,
      });
      console.log("response", response.data);
      store.mutations.setToken(response.data.token);
      store.mutations.setRefreshToken(response.data.refreshToken);
      store.mutations.setUser(response.data.user);
    } else {
      store.mutations.setToken(token);
      store.mutations.setRefreshToken(refreshToken);
      store.mutations.setUser(user);
    }
    return;
  }

  async function verifyTwoFactor(payload) {
    try {
      const response = await api.post("/auth/verify-2-factor", payload);
      store.mutations.setUser(response.data.user);
      store.mutations.setToken(response.data.token);
      store.mutations.setRefreshToken(response.data.refreshToken);
      return response;
    } catch (error) {
      console.error("Error verifying 2FA code:", error);
      throw error;
    }
  }

  async function login(credentials) {
    try {
      const response = await api.post("/auth/login", credentials);
      store.mutations.setUser(response.data.user);
      store.mutations.setToken(response.data.token);
      store.mutations.setRefreshToken(response.data.refreshToken);
      return response;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  }

  async function refreshAccessToken() {
    try {
      const response = await api.post("/auth/refresh-token", {
        token: refreshToken.value,
      });
      console.log("response", response.data);
      store.mutations.setToken(response.data.token);
      store.mutations.setRefreshToken(response.data.refreshToken);
    } catch (error) {
      console.error("Error refreshing access token:", error);
      throw error;
    }
  }

  async function logout() {
    try {
      store.mutations.clearAuth();
      clearTimeout(refreshTimeout); // Clear the timeout when logging out
    } catch (error) {
      console.error("Error logging out:", error);
      throw error;
    }
  }

  async function verifyEmailToken(token) {
    try {
      const response = await api.post("/auth/verify-email", { token });
      store.mutations.setUser(response.data.user);
      return response.data;
    } catch (error) {
      console.error("Error verifying email token:", error);
      throw error;
    }
  }

  async function resetPassword(email) {
    try {
      await api.post("/auth/reset-password", { email });
    } catch (error) {
      console.error("Error resetting password:", error);
      throw error;
    }
  }

  async function updateProfile(profileData) {
    try {
      const response = await api.put("/auth/update-profile", profileData);
      store.mutations.setUser(response.data.user);
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  }

  // onUnmounted(() => {
  //   if (isPrimary.value) {
  //     clearTimeout(refreshTimeout); // Clean up the timeout when the hook is unmounted
  //   }
  // });

  // watch(
  //   token,
  //   () => {
  //     if (token.value) {
  //       if (isPrimary.value) {
  //         // scheduleTokenRefresh(); // Reschedule token refresh when the token changes
  //       }
  //     } else {
  //       // if (refreshTimeout) clearTimeout(refreshTimeout); // Clear the timeout if the token is removed
  //     }
  //   },
  //   { immediate: true }
  // );

  // Handle browser visibility change (e.g., laptop wakes up)
  // const handleVisibilityChange = () => {
  //   if (document.visibilityState === "visible") {
  //     console.log("Document is visible, checking auth tokens...");
  //     // scheduleTokenRefresh();
  //   }
  // };

  // // Handle network reconnection
  // const handleOnlineStatus = () => {
  //   console.log("Network status changed, online:", navigator.onLine);
  //   if (navigator.onLine) {
  //     // scheduleTokenRefresh();
  //   }
  // };

  onMounted(() => {
    if (!hasMounted.value) {
      hasMounted.value = true;
      isPrimary.value = true;
      // document.addEventListener("visibilitychange", handleVisibilityChange);
      // window.addEventListener("online", handleOnlineStatus);
      // window.addEventListener("offline", handleOnlineStatus);
    }
  });

  // Clean up event listeners on unmount
  onUnmounted(() => {
    if (isPrimary.value) {
      console.log("auth unmount");
      // document.removeEventListener("visibilitychange", handleVisibilityChange);
      // window.removeEventListener("online", handleOnlineStatus);
      // window.removeEventListener("offline", handleOnlineStatus);
    }
  });

  watch(
    [token, isPrimary],
    async () => {
      if (isPrimary.value && token.value) {
        const { getPreferences } = usePreferenceStore();
        await getPreferences();
      }
    },
    { immediate: true }
  );

  return {
    user,
    authCode,
    isAuthenticated,
    isAuthenticating,
    token,
    refreshToken,
    verifyTwoFactor,
    login,
    logout,
    refreshAccessToken,
    verifyEmailToken,
    resetPassword,
    updateProfile,
    validateTokenRefresh,
    generateAuthCode,
  };
}
