import { computed, ref } from "vue";
import store from "../store";
import api from "../utils/api";

export function useLabelStore() {
  const isLoading = ref(false);
  const labels = computed(() => store.state.labels);

  async function fetchLabels(query) {
    try {
      isLoading.value = true;
      const response = await api.get("/api/labels", { params: query });
      store.mutations.setState("labels", response.data);
    } catch (error) {
      console.error("Error fetching labels:", error);
    } finally {
      isLoading.value = false;
    }
  }

  async function createNoteLabel(labelData) {
    try {
      const response = await api.post(
        `/api/notes/${labelData.noteId}/labels`,
        labelData
      );
      const note = store.state.notes?.find(
        (note) => note.id === labelData.noteId
      );
      if (note) {
        store.mutations.updateModel("notes", labelData.noteId, {
          ...note,
          labels: [...note.labels, response.data],
        });
      }

      return response.data;
    } catch (error) {
      console.error("Error creating label:", error);
      throw error;
    }
  }

  async function createLabel(labelData) {
    try {
      const response = await api.post(`/api/labels`, labelData);
      store.mutations.addModel("labels", response.data);
      return response.data;
    } catch (error) {
      console.error("Error creating label:", error);
      throw error;
    }
  }

  async function updateLabel(id, labelData) {
    try {
      const response = await api.put(`/api/labels/${id}`, labelData);
      store.mutations.updateModel("labels", id, response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating label:", error);
      throw error;
    }
  }

  async function deleteLabel(id) {
    try {
      await api.delete(`/api/labels/${id}`);
      store.mutations.removeModel("labels", id);
    } catch (error) {
      console.error("Error deleting label:", error);
      throw error;
    }
  }

  async function deleteNoteLabel(id, noteId) {
    try {
      await api.delete(`/api/notes/${noteId}/labels/${id}`);
      const note = store.state.notes?.find((note) => note.id === noteId);
      if (note) {
        store.mutations.updateModel("notes", noteId, {
          ...note,
          labels: note.labels.filter((n) => n.id !== id),
        });
      }
    } catch (error) {
      console.error("Error deleting label:", error);
      throw error;
    }
  }

  async function fetchLabelById(labelId) {
    try {
      const response = await api.get(`/api/labels/${labelId}`);
      store.mutations.addModel("labels", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching label by id:", error);
      throw error;
    }
  }

  async function searchLabels(query) {
    try {
      const response = await api.get("/api/labels/search", { params: query });
      return response.data;
    } catch (error) {
      console.error("Error searching labels:", error);
      throw error;
    }
  }

  return {
    labels,
    isLoading,
    fetchLabels,
    createLabel,
    updateLabel,
    deleteLabel,
    fetchLabelById,
    searchLabels,
    createNoteLabel,
    deleteNoteLabel,
  };
}
