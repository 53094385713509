<template>
  <div class="icon">
    <i class="pi pi-thumbtack"></i>
    <!-- Pin logic goes here -->
  </div>
</template>

<script setup></script>

<style scoped>
.icon {
  cursor: pointer;
  margin-left: auto;
}
</style>
