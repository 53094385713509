import { computed, ref } from "vue";
import store from "../store";
import api from "../utils/api";

export function useCommentStore() {
  const isLoading = ref(false);
  const comments = computed(() => store.state.comments);

  async function fetchComments(query) {
    try {
      isLoading.value = true;
      const response = await api.get("/api/comments", { params: query });
      store.mutations.setState("comments", response.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      isLoading.value = false;
    }
  }

  async function createComment(commentData) {
    try {
      const response = await api.post("/api/comments", commentData);
      store.mutations.addModel("comments", response.data);
      return response.data;
    } catch (error) {
      console.error("Error creating comment:", error);
      throw error;
    }
  }

  async function updateComment(id, commentData) {
    try {
      const response = await api.put(`/api/comments/${id}`, commentData);
      store.mutations.updateModel("comments", id, response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating comment:", error);
      throw error;
    }
  }

  async function deleteComment(id) {
    try {
      await api.delete(`/api/comments/${id}`);
      store.mutations.removeModel("comments", id);
    } catch (error) {
      console.error("Error deleting comment:", error);
      throw error;
    }
  }

  async function fetchCommentById(commentId) {
    try {
      const response = await api.get(`/api/comments/${commentId}`);
      store.mutations.addModel("comments", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching comment by id:", error);
      throw error;
    }
  }

  async function searchComments(query) {
    try {
      const response = await api.get("/api/comments", { params: query });
      return response.data;
    } catch (error) {
      console.error("Error searching comments:", error);
      throw error;
    }
  }

  async function likeComment(id) {
    try {
      const response = await api.post(`/api/comments/${id}/like`);
      store.mutations.updateModel("comments", id, response.data);
    } catch (error) {
      console.error("Error liking comment:", error);
      throw error;
    }
  }

  async function replyToComment(parentId, commentData) {
    try {
      const response = await api.post(
        `/api/comments/${parentId}/replies`,
        commentData
      );
      store.mutations.addModel("comments", response.data);
      return response.data;
    } catch (error) {
      console.error("Error replying to comment:", error);
      throw error;
    }
  }

  return {
    comments,
    isLoading,
    fetchComments,
    createComment,
    updateComment,
    deleteComment,
    fetchCommentById,
    searchComments,
    likeComment,
    replyToComment,
  };
}
