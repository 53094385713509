import { Capacitor } from "@capacitor/core";
import { LocalNotifications } from "@capacitor/local-notifications";
import { useToast } from "primevue/usetoast";

const isNative =
  Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android";

export function playDing() {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const oscillator = audioContext.createOscillator();
  const gainNode = audioContext.createGain();

  oscillator.type = "triangle"; // 'triangle' waveform for a softer sound
  oscillator.frequency.setValueAtTime(1100, audioContext.currentTime); // higher frequency for a "ding"

  // Gradually reduce the volume to create a fading "ding"
  gainNode.gain.setValueAtTime(1, audioContext.currentTime); // start at full volume
  gainNode.gain.exponentialRampToValueAtTime(
    0.001,
    audioContext.currentTime + 0.5
  ); // fade out over 1 second

  oscillator.connect(gainNode);
  gainNode.connect(audioContext.destination);

  oscillator.start();
  oscillator.stop(audioContext.currentTime + 1); // play for 1 second
}

export function useNotify() {
  const notify = async (title, message, data, toastOptions) => {
    // Check if the platform is native (iOS or Android)
    if (isNative) {
      try {
        // Request permission for notifications
        const hasPermission = await requestNotificationPermission();
        if (hasPermission) {
          // Show local notification for native devices
          await showLocalNotification(title, message, data);
        } else {
          console.log("Notification permission denied");
          // Fallback to Toast if permission is not granted
          showToast(message, toastOptions);
        }
      } catch (error) {
        console.error("Error showing native notification:", error);
        showToast(message, toastOptions); // Fallback to toast on error
      }
    } else {
      console.log("notify web");
      // For web: Check if Web Notifications are supported and use them
      const permission = await requestWebNotificationPermission();
      console.log("permission", permission);
      if (permission === "granted") {
        showWebNotification(title, message, data);
      } else {
        // Fallback to showing a toast notification if permission is denied or not supported
        showToast(message, toastOptions);
      }
    }
  };

  const showLocalNotification = async (title, message, data) => {
    await LocalNotifications.schedule({
      notifications: [
        {
          title: `Local: ${title}`,
          body: message,
          id: new Date().getTime(),
          schedule: { at: new Date(Date.now() + 1000) },
          sound: null,
          attachments: null,
          extra: data,
        },
      ],
    });
  };

  const showWebNotification = (title, message, data = {}) => {
    const notificationOptions = {
      body: message,
      //   icon: "/path/to/icon.png", // Replace with the path to your notification icon
      data: data,
    };

    // Show the web notification
    const notification = new Notification(title, notificationOptions);
    notification.onerror = (e) => {
      console.log("error", e);
    };
    notification.onshow = (e) => {
      console.log("onshow", e);
    };
    // Add click event to the notification (optional)
    notification.onclick = () => {
      console.log("Notification clicked");
      // You can also navigate the user to a specific route
      window.location.href = `/note/${data.noteId}`;
    };
    console.log("Notification shown:", notification);
  };

  const showToast = (message, toastOptions = {}) => {
    const { toast } = useToast();
    const defaultToast = {
      severity: "info",
      summary: "Notification",
      detail: message,
      life: 3000,
      reminder: true,
    };
    toast.add({ ...defaultToast, ...toastOptions });
  };

  const requestNotificationPermission = async () => {
    console.log("requestNotificationPermission", isNative);
    if (isNative) {
      const { display } = await LocalNotifications.requestPermissions();
      console.log("granted", display === "granted");
      return display === "granted";
    }
    return false; // Assume permission is not needed on web
  };

  const requestWebNotificationPermission = async () => {
    if (!("Notification" in window)) {
      console.log("Web notifications are not supported in this browser.");
      return "denied";
    }

    const permission = await Notification.requestPermission();
    return permission;
  };

  return { notify };
}
